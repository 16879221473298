import React from "react";

const InputPassword = (props) => (
  <input
    className={props.className}
    id={props.id}
    type={props.type}
    name={props.name}
    placeholder={props.placeholder}
    onChange={props.onChange}
    value={props.value}
  ></input>
);

export default InputPassword;
