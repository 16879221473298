import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getAllFrontEndUsers } from "../../redux/slice/backendUserSlice";
import { SEARCH_FRONTEND_USER } from "../../api";
import { scrollToTop } from "../../redux/handleUser";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import UserRoleHead from "../../components/headings/Heading2";
import GoToPage from "../../components/pagination/GoToPage";
import Pagination from "../../components/pagination/Pagination";
import Search from "../../components/search/Search";
import axiosFetch from "../../axiosConfig";

const FrontEndUsers = ({ setProgress }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // constant to use useTranslation
  const { t } = useTranslation();
  // get the user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  const page = new URLSearchParams(window.location.search).get("page");
  const [limit] = useState(5);
  const [searchUserKeyword, setSearchUserKeyword] = useState("");
  const [searchUserResponse, setSearchUserResponse] = useState();
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(() => {
    return page ? parseInt(page) : 1;
  });
  const [inputPage, setInputPage] = useState("");

  // getting all the front user
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(
      getAllFrontEndUsers({
        limit: limit,
        offset: (currentPage - 1) * limit,
      })
    ).then(() => {
      setLoader(false);
      scrollToTop();
      setProgress(100);
    });
  }, [limit, currentPage, dispatch, setProgress]);
  // getting all the front user detais from admin state
  const {
    frontEndUserDetails: { allFrontEndUsers },
  } = useSelector((state) => state.admin);
  const { userPermission } = useSelector((state) => state.admin);

  //function to seach front end user
  useEffect(() => {
    if (searchUserKeyword.length > 0) {
      const searchUser = async () => {
        try {
          const resp = await axiosFetch.get(SEARCH_FRONTEND_USER + "?search=" + searchUserKeyword);
          setSearchUserResponse(resp.data);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error.response);
        }
      };
      searchUser();
    }
  }, [searchUserKeyword]);

  // functions for handling pagination
  const handlePrev = () => {
    let tempCurrentPage = currentPage; // store current page in temporary var
    if (currentPage > 1) {
      tempCurrentPage -= 1; // decrement page by one
      setCurrentPage(parseInt(tempCurrentPage)); // update current page
      navigate(`?page=${tempCurrentPage}`); //insert the page no in path param
      if (tempCurrentPage === 1) {
        navigate(window.location.pathname); //if page no is 1 then replace with origin URL
        return;
      }
    }
  };

  const handleNext = () => {
    let tempCurrentPage = currentPage;
    // return if user is on last page
    if (tempCurrentPage >= Math.ceil(allFrontEndUsers?.total / limit)) return;
    tempCurrentPage += 1; // increment the page by one
    setCurrentPage(parseInt(tempCurrentPage)); // update current page
    navigate(`?page=${tempCurrentPage}`); // insert the page no in path param
  };

  const handleInputPage = () => {
    if (inputPage < 0 || inputPage === "" || inputPage > Math.ceil(allFrontEndUsers?.total / limit))
      return; // return with doing anything
    setCurrentPage(parseInt(inputPage)); // set the input page as current page
    navigate(`?page=${inputPage}`); // insert the page no in path param
  };

  return (
    <DashboardWrapper>
      <UserRoleHead align="center">{t("frontend") + " " + t("user")}</UserRoleHead>
      {(userPermission.includes("read_user") || user?.role === "superuser") && (
        <Search
          value={searchUserKeyword}
          onChange={(e) => setSearchUserKeyword(e.target.value)}
          searchResponse={searchUserResponse?.results}
          clickURL="frontend-user-details"
          title="email"
          elemuid="uuid"
          placeholder="Enter search keyword"
        />
      )}
      {!allFrontEndUsers?.data?.detail && (
        <div className={loader ? "not-appeared" : "appeared"}>
          <div className="cd-front-end-user-list-container">
            {allFrontEndUsers?.users &&
              allFrontEndUsers?.users.map((user, index) => (
                <div key={index} className="cd-front-end-user-list-element">
                  <div>
                    <img
                      height={{ height: "10px" }}
                      className="cd-user-profile-thumbnail"
                      src={`https://codecms.codeholic.in/static/uploads/${user.profile_photo}`}
                      alt=""
                    />
                  </div>
                  <div>
                    {t("name")} : {user?.first_name + " " + user?.last_name}
                  </div>
                  <div>
                    {t("email")} : {user?.email}{" "}
                    <span className="cd-success-msg">
                      {user?.email_verified_at ? `(${t("verified")})` : ""}
                    </span>
                  </div>
                  <div>
                    {t("username")} : {user?.username}
                  </div>
                  {user?.is_deleted === true && <div className="cd-err-msg">Deleted</div>}
                  {user?.is_active === false && (
                    <div className="cd-err-msg">{t("deactivated")}</div>
                  )}
                  <Link
                    className="cd-common-anchor-tag"
                    to={`/frontend-user-details/${user?.uuid}`}
                  >
                    {t("details")}
                  </Link>
                </div>
              ))}
          </div>
          <Pagination
            onClickPrev={handlePrev}
            onClickNext={handleNext}
            currentPage={currentPage}
          ></Pagination>
          <GoToPage
            inputPage={inputPage}
            onClick={handleInputPage}
            onChange={(e) => setInputPage(e.target.value)}
          ></GoToPage>
        </div>
      )}
      {allFrontEndUsers?.data?.detail && (
        <div className="cd-err-msg">{allFrontEndUsers?.data?.detail?.detail[0].msg}</div>
      )}
    </DashboardWrapper>
  );
};

export default FrontEndUsers;
