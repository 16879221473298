import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { adminLogin } from "../../redux/slice/backendUserSlice";
import Heading2 from "../../components/headings/Heading2";
import InputField from "../../components/inputs/InputField";
import InputPassword from "../../components/inputs/InputPassword";
import Button from "../../components/buttons/Button";
import LanguageChanger from "../../components/LanguageChanger";
import Loader2 from "../../components/loaders/Loader2";
import axiosFetch from "../../axiosConfig";
import "../../style/login.css";
import "../../App.css";

function AdminLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // getting the data of admin state from
  const { adminLogindata } = useSelector((state) => state.admin);
  // useTranslation() hook is stored in constant "t",
  // we can use it to translate particular text by passing the key as parameter
  const { t } = useTranslation();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userIp, setUserIp] = useState();
  const user = JSON.parse(localStorage.getItem("user"));

  // variable to disable the login button if input field length is less than four
  const isDisabled = userName.length < 4 || password.length < 4 ? true : false;

  // redirect the user to dashboard if user already login
  // we call it inside useEffect because we can't change browser route while component is rendering
  // useEffect runs after page render
  useEffect(() => {
    if (user?.token) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (!user?.token) {
      const getTheIp = async () => {
        const resp = await axiosFetch.get("https://api.ipify.org/?format=json");
        setUserIp(resp.data.ip);
      };
      getTheIp();
    }
  }, [user?.token]);

  // Getting the browser information
  const getBrowserName = () => {
    let browserInfo = navigator.userAgent;
    let browser;
    if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
      browser = "Opera";
    } else if (browserInfo.includes("Edg")) {
      browser = "Edge";
    } else if (browserInfo.includes("Chrome")) {
      browser = "Chrome";
    } else if (browserInfo.includes("Safari")) {
      browser = "Safari";
    } else if (browserInfo.includes("Firefox")) {
      browser = "Firefox";
    } else {
      browser = "unknown";
    }
    return browser;
  };
  const browser = getBrowserName();
  const system = navigator.platform;

  const loginUser = async (e) => {
    setLoader(true);
    e.preventDefault();
    dispatch(
      adminLogin({
        username_or_email: userName,
        password: password,
        details: {
          ip_address: userIp,
          browser: browser,
          system: system,
        },
      })
    ).then(() => {
      setTimeout(() => {
        setLoader(false);
        window.location.replace("/dashboard");
      }, 1500);
    });
  };

  // hide the password after 2 seconds
  let timeoutId;
  useEffect(() => {
    // Clear the previous timeout if it exists
    clearTimeout(timeoutId);

    if (passwordVisibility) {
      timeoutId = setTimeout(() => {
        setPasswordVisibility(false);
      }, 2000);
    }
    // function to clear the timeout when the component unmounts or when passwordVisibility changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [passwordVisibility]);

  return (
    <>
      <div className="cd-card-container">
        <div>
          <LanguageChanger />
        </div>
        <div className="cd-card">
          <Heading2 className="cd-user-role-head">{t("login")}</Heading2>
          <form onSubmit={loginUser}>
            <div className="cd-input-container">
              <label className="cd_username_label cd-input-label" htmlFor="username">
                {t("username")}/{t("email")} :
              </label>
              <InputField
                className="cd-input-field"
                type="text"
                name="username"
                id="username"
                placeholder={t("placeholderUsername")}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="cd-input-container cd-eye-button-parent">
              <label className="cd_username_label cd-input-label" htmlFor="password">
                {t("password")}:{" "}
              </label>
              <InputPassword
                className="cd-input-field"
                type={passwordVisibility ? "text" : "password"}
                name="password"
                id="password"
                placeholder={t("placeholderPassword")}
                onChange={(e) => setPassword(e.target.value)}
              />
              <img
                className="cd-eye-button-img"
                src="/eyeball.png"
                alt="eye-button"
                onClick={() => setPasswordVisibility(!passwordVisibility)}
              />
            </div>
            <div className="cd-btn-container">
              <Button id="loginButton" className="cd-btn" type="submit" disabled={isDisabled}>
                {" "}
                {t("login")}
                {loader && <Loader2 />}
              </Button>
            </div>
          </form>
          {adminLogindata?.token && (
            <div className="cd-msg-container">
              <div className="cd-success-msg">Login Success! Redirecting...</div>
            </div>
          )}
          <div className="cd-msg-container">
            {adminLogindata?.detail?.detail && (
              <div className="cd-err-msg">{t(adminLogindata?.detail?.detail[0]?.msg)}</div>
            )}
          </div>
          {/* <div className='cd_not_have_account_container'>
                        <div>
                            {t("notHaveAcc")}
                        </div>
                        <div className='cd_sign_up_link_container'>
                            <Link className='cd_sign_up_link' to='/signup'>
                                {t("signUp")}
                            </Link>
                        </div>
                    </div> */}
          <div>
            <Link className="cd-common-anchor-tag" to="/send-email-verfication-token">
              Forgot Password..?
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminLogin;
