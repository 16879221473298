import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllBackendUsers } from "../../redux/slice/backendUserSlice";
import { scrollToTop } from "../../redux/handleUser";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Pagination from "../../components/pagination/Pagination";
import GoToPage from "../../components/pagination/GoToPage";

const ManageUsers = ({ setProgress }) => {
  const page = new URLSearchParams(window.location.search).get("page");
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation(); // destructure translation function from useTranslation hook
  const dispatch = useDispatch(); // constant to use useDispatch hook
  const navigate = useNavigate(); // constant to use useNavigate hook

  const [limit] = useState(10);
  const [currentPage, setCurrentPage] = useState(() => {
    return page ? parseInt(page) : 1;
  }); // set the page from URL bar else set one
  const [inputPage, setInputPage] = useState("");
  const [loader, setLoader] = useState(true);
  // get the required data from admin state
  const {
    backendUsersData: { allBackendUsers },
  } = useSelector((state) => state.admin);
  const { userPermission } = useSelector((state) => state.admin);

  // getting all the backend users
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(
      getAllBackendUsers({
        limit: limit,
        offset: (currentPage - 1) * limit,
      })
    ).then(() => {
      setProgress(100);
      setLoader(false);
      scrollToTop();
    });
  }, [limit, currentPage, dispatch, setProgress]);
  // console.log(allBackendUsers);

  // functions for handling pagination
  const handlePrev = () => {
    let tempCurrentPage = currentPage;
    if (currentPage > 1) {
      tempCurrentPage -= 1;
      setCurrentPage(parseInt(tempCurrentPage));
      navigate(`?page=${tempCurrentPage}`);
      if (tempCurrentPage === 1 && page) {
        navigate(window.location.pathname);
        return;
      }
    }
  };

  const handleNext = () => {
    let tempCurrentPage = currentPage;
    if (tempCurrentPage >= Math.ceil(allBackendUsers?.total / limit)) return;
    tempCurrentPage += 1;
    setCurrentPage(parseInt(tempCurrentPage));
    navigate(`?page=${tempCurrentPage}`);
  };

  const handleInputPage = () => {
    if (inputPage < 0 || inputPage === "" || inputPage > Math.ceil(allBackendUsers?.total / limit))
      return;
    setCurrentPage(parseInt(inputPage));
    navigate(`?page=${inputPage}`);
  };
  return (
    <DashboardWrapper>
      <div>
        <div>
          <h2 align="center">{t("backend") + " " + t("user")}</h2>
        </div>

        {/* check user permission */}
        {(userPermission.includes("create_user") || user?.role === "superuser") && (
          <div>
            <Link to="/create-user">{t("createUser")}</Link>
          </div>
        )}

        {!allBackendUsers?.data?.detail && (
          <div className={loader ? "not-appeared" : "appeared"}>
            <div className="cd-backend-users-list-container">
              {allBackendUsers?.users?.map((data, index) => (
                <div key={index} className="cd-backend-users-list-element">
                  <div className="cd_backend_user_username">
                    {t("username")} : {data?.username}{" "}
                    {data?.is_active === false ? (
                      <span className="cd-err-msg">(Deactivated)</span>
                    ) : (
                      ""
                    )}
                  </div>
                  {data?.email_verified_at !== null && (
                    <div className="cd_backend_user_email">
                      {t("email")} : {data?.email}
                    </div>
                  )}
                  {data?.email_verified_at == null && (
                    <div className="cd-err-msg">
                      {t("email")} : {data?.email}(not verified)
                    </div>
                  )}
                  <div></div>
                  <div className="cd_backend_user_role">
                    {t("role")} : {data?.role?.title}
                  </div>
                  <div>
                    <Link to={`/user/${data?.uuid}`} className="cd-common-anchor-tag">
                      {t("details")}
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            <Pagination
              onClickPrev={handlePrev}
              onClickNext={handleNext}
              currentPage={currentPage}
            />

            <GoToPage
              inputPage={inputPage}
              onChange={(e) => setInputPage(e.target.value)}
              onClick={handleInputPage}
            />
          </div>
        )}
        {allBackendUsers?.data?.detail && (
          <div className="cd-err-msg">{allBackendUsers?.data?.detail[0]?.msg}</div>
        )}
        {allBackendUsers?.data?.detail?.detail && (
          <div className="cd-err-msg">{allBackendUsers?.data?.detail?.detail[0]?.msg}</div>
        )}
      </div>
    </DashboardWrapper>
  );
};

export default ManageUsers;
