import React from "react";

const DashboardSidebar = (props) => {
  return (
    <>
      <div>
        {/* <div className='cd-dashboard-strip'>
                  CodeCMS
                </div> */}
        <div className="cd-dashboard-strip-controls">{props.children}</div>
      </div>
    </>
  );
};

export default DashboardSidebar;
