import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { CREATE_COUPON } from "../../api";
import { Card1 } from "../../components/commoncomps/Card1";
import axiosFetch from "../../axiosConfig";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Heading2 from "../../components/headings/Heading2";
import InputField from "../../components/inputs/InputField";
import Button from "../../components/buttons/Button";
import Loader from "../../components/loaders/Loader";
const CreateCoupon = () => {
  // constant to use useTranslation hook
  const { t } = useTranslation();
  // get the user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  const [couponTitle, setCouponTitle] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponPercentage, setCouponPercentage] = useState("");
  const [validationError, setValidationError] = useState("");
  const [addCouponResponse, setAddCouponResponse] = useState("");
  const [loader, setLoader] = useState(false);

  // get the user permission from admin state
  const { userPermission } = useSelector((state) => state.admin);

  // const to disable to create-coupon button
  const isDisabled =
    couponTitle.length < 3 ||
    couponTitle.length > 30 ||
    couponCode.length < 3 ||
    couponCode.length > 30 ||
    couponPercentage.length < 1
      ? true
      : false;

  // function to check validations and hit the API of create coupon
  const handleCreateCoupon = async () => {
    let tempValidationError = "";

    // input validation
    if (couponTitle.length < 3) tempValidationError += t("titleLengthValidationMsg3") + "\n";
    if (couponTitle.length > 30) tempValidationError += t("titleLengthValidationMsg30") + "\n";
    if (couponCode.length < 3) tempValidationError += t("couponCodeLengthValidationMsg3") + "\n";
    if (couponCode.length > 30) tempValidationError += t("couponCodeLengthValidationMsg30") + "\n";

    // if length is not greater then 0 means no validation error, we'll hit the API in this case
    if (tempValidationError.length > 0) {
      setValidationError(tempValidationError);
      setAddCouponResponse("");
      return;
    } else {
      setLoader(true);
      setValidationError("");
      try {
        const resp = await axiosFetch.post(CREATE_COUPON, {
          coupon_code: couponCode,
          title: couponTitle,
          percentage: couponPercentage,
          coupon_type: "percentage",
        });
        if (resp?.status === 201) {
          setAddCouponResponse(resp?.data);
          setLoader(false);
        }
      } catch (error) {
        // console.log(error?.response);
        setAddCouponResponse(error?.response?.data);
        setLoader(false);
      }
    }
  };

  // chech the user permission
  if (!userPermission.includes("create_code") && user?.role !== "superuser") {
    return (
      <DashboardWrapper>
        <div className="cd-err-msg">You don't have access to view this page</div>
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper>
      <div>
        <Heading2 align="center">{t("createCoupon")}</Heading2>
      </div>
      <Card1>
        <div>
          <div className="cd-input-container">
            <label className="cd-input-label">{t("title")}:</label>
            <InputField
              placeholder={t("placeholderTitle")}
              className="cd-input-field"
              name="cd_coupon_title"
              onChange={(e) => setCouponTitle(e.target.value)}
            ></InputField>
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label">{t("couponCode")}:</label>
            <InputField
              placeholder={t("placeholderCouponCode")}
              className="cd-input-field"
              name="cd_coupon_code"
              onChange={(e) => setCouponCode(e.target.value)}
            ></InputField>
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label">{t("percentage")}:</label>
            <InputField
              type="number"
              placeholder={t("placeholderPercentage")}
              className="cd-input-field"
              name="cd_coupon_percent"
              onChange={(e) => setCouponPercentage(e.target.value)}
            ></InputField>
          </div>
          <div className="cd-err-msg" style={{ whiteSpace: "pre-line" }}>
            {validationError.length > 0 ? validationError : ""}
          </div>
          {/* code block to show the loader conditionally */}
          {loader && <Loader />}
          {/* code block to show success response */}
          {addCouponResponse?.title && (
            <div className="cd-success-msg">{t("couponCreatedMsg")}</div>
          )}
          {/* code block to show error message */}
          {addCouponResponse?.detail && (
            <div className="cd-err-msg">{addCouponResponse?.detail[0]?.msg}</div>
          )}
          {addCouponResponse?.detail?.detail && (
            <div className="cd-err-msg">{addCouponResponse?.detail?.detail[0]?.msg}</div>
          )}
          <Button className="cd-btn" onClick={handleCreateCoupon} disabled={isDisabled}>
            {t("createCoupon")}
          </Button>
        </div>
      </Card1>
    </DashboardWrapper>
  );
};

export default CreateCoupon;
