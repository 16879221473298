import { useState, React, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useDispatch } from "react-redux";

import { getUserPermission } from "./redux/slice/backendUserSlice";
import Homepage from "./pages/Homepage";
import AdminLogin from "./pages/authentication/AdminLogin";
import Dashboard from "./pages/Dashboard";
import CreateUser from "./pages/backendusers/CreateUser";
import VerifyToken from "./pages/authentication/VerifyToken";
import { AddRole } from "./pages/role/AddRole";
import ManageRoles from "./pages/role/ManageRoles";
import Subscriptions from "./pages/subscriptions/Subscriptions";
import AddSubscription from "./pages/subscriptions/AddSubscription";
import ManageUsers from "./pages/backendusers/ManageUsers";
import ResetPassword from "./pages/authentication/ResetPassword";
import SendToken from "./pages/authentication/SendToken";
import SubscriptionDetail from "./pages/subscriptions/SubscriptionDetail";
import UpdateSubscription from "./pages/subscriptions/UpdateSubscription";
import FrontEndUsers from "./pages/frontendusers/FrontEndUsers";
import FrontEndUserDetails from "./pages/frontendusers/FrontEndUserDetails";
import ChangeLanguage from "./pages/ChangeLanguage";
import TaxModels from "./pages/taxModels/TaxModels";
import AddTaxModel from "./pages/taxModels/AddTaxModel";
import TaxModelDetails from "./pages/taxModels/TaxModelDetails";
import Coupons from "./pages/coupon/Coupons";
import CreateCoupon from "./pages/coupon/CreateCoupon";
import UpdateCoupon from "./pages/coupon/UpdateCoupon";
import UserDetails from "./pages/backendusers/UserDetails";
import Permissions from "./pages/permission/Permissions";
import CreatePermission from "./pages/permission/CreatePermission";
import "./App.css";
import "./index.css";

function App() {
  const [progress, setProgress] = useState(0);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [showOnlineMsg, setShowOnlineMsg] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  // constant to use useDispatch;
  const dispatch = useDispatch();

  // function to check the connection of user
  useEffect(() => {
    window.addEventListener("online", () => {
      setIsOnline(true); // change the is online state
      setShowOnlineMsg(true); // show the back to online msg
      setTimeout(() => {
        window.location.reload(); // reload the page
      }, 2000);
    });
    window.addEventListener("offline", () => {
      setIsOnline(false); // change the online state to false to show offline msg
    });
  }, []);

  // dispatch the action to get current user permission
  // the response of this API used for showing MenuItems conditionally
  useEffect(() => {
    if (user) {
      dispatch(getUserPermission());
    }
  }, [dispatch]);

  return (
    <div className={!isOnline ? "disable-pointer" : ""}>
      <div className={progress === 100 ? "not-appeared-top-bar" : "appeared-top-bar"}>
        <LoadingBar
          color="#f55a06"
          shadow={true}
          height="3px"
          progress={progress}
          loaderSpeed={400}
          transitionTime={50}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>

      {/* if user not online */}
      {!isOnline && (
        <div align="center" className="cd-connection-off-msg">
          You're offline, Please connect to the internet
        </div>
      )}
      {/* if user connected back to internet */}
      {showOnlineMsg && (
        <div align="center" className="cd-connection-on-msg">
          Back to online, please wait...
        </div>
      )}
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/login" element={<AdminLogin />} />
          <Route exact path="/backend-user/verify-token" element={<VerifyToken />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/users" element={<ManageUsers setProgress={setProgress} />} />
          <Route exact path="/user/:uuid" element={<UserDetails setProgress={setProgress} />} />
          <Route exact path="/create-user" element={<CreateUser setProgress={setProgress} />} />
          <Route exact path="/create-role" element={<AddRole />} />
          <Route exact path="/change-language" element={<ChangeLanguage />} />
          <Route exact path="/manage-roles" element={<ManageRoles setProgress={setProgress} />} />
          <Route
            exact
            path="/subscriptions"
            element={<Subscriptions setProgress={setProgress} />}
          />
          <Route
            exact
            path="/subscription/:suid"
            element={<SubscriptionDetail setProgress={setProgress} />}
          />
          <Route exact path="/add-subscription" element={<AddSubscription />} />
          <Route
            exact
            path="/update-subscription/:suid"
            element={<UpdateSubscription setProgress={setProgress} />}
          />
          <Route exact path="/send-email-verfication-token" element={<SendToken />} />
          <Route exact path="/backend-user/create-password" element={<ResetPassword />} />
          <Route
            exact
            path="/frontend-users"
            element={<FrontEndUsers setProgress={setProgress} />}
          />
          <Route
            exact
            path="/frontend-user-details/:uuid"
            element={<FrontEndUserDetails setProgress={setProgress} />}
          />
          <Route exact path="/tax-models" element={<TaxModels setProgress={setProgress} />} />
          <Route
            exact
            path="/tax-models/:tuid"
            element={<TaxModelDetails setProgress={setProgress} />}
          />
          <Route exact path="/add-tax-model" element={<AddTaxModel />} />
          <Route exact path="/coupons" element={<Coupons setProgress={setProgress} />} />
          <Route exact path="/coupons/create-coupon" element={<CreateCoupon />} />
          <Route
            exact
            path="/coupons/update-coupon/:couponCode"
            element={<UpdateCoupon setProgress={setProgress} />}
          />
          <Route
            exact
            path="/permissions"
            element={<Permissions setProgress={setProgress} />}
          ></Route>
          <Route exact path="/create-permission" element={<CreatePermission />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
