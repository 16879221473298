import React from "react";

const Loader2 = () => {
  return (
    // eslint-disable-next-line react/no-unknown-property
    <div align="center" style={{ display: "inline", margin: "5px", position: "relative" }}>
      <span className="cd-loader-2"></span>
    </div>
  );
};

export default Loader2;
