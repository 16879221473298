import React, { useState } from "react";

import axiosFetch from "../../axiosConfig";
import { CHANGE_PASSWORD_ENDPOINT } from "../../api";
import { Card1 } from "../../components/commoncomps/Card1";
import InputPassword from "../../components/inputs/InputPassword";
import Button from "../../components/buttons/Button";

const ResetPassword = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();
  const [response, setResponse] = useState();
  const submitChangePassword = async () => {
    try {
      const resp = await axiosFetch.post(CHANGE_PASSWORD_ENDPOINT, {
        token: token,
        password: password2,
      });
      setResponse(resp);
    } catch (error) {
      setResponse(error?.response);
    }
  };
  return (
    <Card1>
      <div>
        <h2 className="cd-user-role-head">Reset Password</h2>
      </div>
      <div className="cd-input-container">
        <label className="cd-input-label" htmlFor="">
          Enter new password:
        </label>
        <InputPassword
          className="cd-input-field"
          type="password"
          name="cd_user_password"
          placeholder="Enter password"
          onChange={(e) => setPassword1(e.target.value)}
        />
      </div>
      <div className="cd-input-container">
        <label className="cd-input-label" htmlFor="">
          Confirm password:
        </label>
        <InputPassword
          className="cd-input-field"
          type="password"
          name="cd_user_password_confirm"
          placeholder="Confirm password"
          onChange={(e) => setPassword2(e.target.value)}
        />
      </div>
      <div>{response?.status == 201 && <div className="cd-success-msg">Password Changed</div>}</div>
      <div>{password1 !== password2 && <div className="cd-err-msg">Password mismatch!</div>}</div>
      <div>
        {response?.status != 200 && (
          <div className="cd-err-msg">{response?.data?.detail?.detail[0]?.msg}</div>
        )}
      </div>
      <div>
        <Button onClick={submitChangePassword} className="cd-btn">
          Submit
        </Button>
      </div>
    </Card1>
  );
};

export default ResetPassword;
