import React from "react";
import DashboardWrapper from "../components/wrappers/DashboardWrapper";
import LanguageChanger from "../components/LanguageChanger";

const ChangeLanguage = () => {
  return (
    <div>
      <DashboardWrapper>
        <LanguageChanger />
      </DashboardWrapper>
    </div>
  );
};

export default ChangeLanguage;
