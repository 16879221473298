import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { adminLogout } from "../redux/slice/backendUserSlice";
import "../App.css";

const Navbar = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showControl, setShowControl] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  // show the user controls edit profile and logout
  const showUserControl = () => {
    if (showControl) {
      setShowControl(false);
      return;
    }
    setShowControl(true);
  };

  // dispatch the adminLogout function to log out the user
  const logOutUser = () => {
    dispatch(adminLogout()).then(() => {
      window.location.replace("/login");
    });
  };
  return (
    <div className="cd-navbar-container">
      <div className="cd-navbar-wrapper clearfix">
        <ul className="cd-navbar-element-group clearfix">
          <li className="cd-navbar-element">
            <a className="cd-link" href="/">
              Element
            </a>
          </li>
          <div className="cd-navbar-element cd-user-control-section">
            <div>
              <button onClick={showUserControl} className="cd-user-control-btn">
                <img className="cd-user-icon" src="/user-128.png" height="30px" alt="" />
              </button>
              <div>{user?.username}</div>
            </div>
            {showControl && (
              <div className="cd-user-controls">
                <div>
                  <Link to="/change-language" className="cd-user-control-element">
                    {t("changeLanguage")}
                  </Link>
                </div>
                <div>
                  <span onClick={logOutUser} className="cd-user-control-element">
                    {t("logout")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
