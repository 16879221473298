import React from "react";
import "../App.css";
import { useTranslation } from "react-i18next";

const LanguageChanger = (props) => {
  const { t, i18n } = useTranslation();
  const languageHandler = (e) => {
    const languageValue = e.target.value;
    localStorage.setItem("language", e.target.value);
    i18n.changeLanguage(languageValue);
  };
  return (
    <div className="languageDropdown">
      <div>
        <select className="cd-custom-select" style={{ width: 200 }} onChange={languageHandler}>
          <option value="">{t("selectLanguage")}</option>
          <option value="en">{t("english")}</option>
          <option value="hn">{t("hindi")}</option>
        </select>
      </div>
    </div>
  );
};
export default LanguageChanger;
