import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { getAllTaxModels } from "../../redux/slice/backendUserSlice";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import GoToPage from "../../components/pagination/GoToPage";
import Pagination from "../../components/pagination/Pagination";

const TaxModels = ({ setProgress }) => {
  // constant to use useTranslation hook
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page = new URLSearchParams(window.location.search).get("page");
  // get user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  const [limit] = useState(10);
  const [currentPage, setCurrentPage] = useState(() => {
    return page ? parseInt(page) : 1;
  });
  const [inputPage, setInputPage] = useState("");
  const [loader, setLoader] = useState(true);

  // dispatch getAllTaxModels api of backendUserSlice
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(
      getAllTaxModels({
        limit: limit,
        offset: (currentPage - 1) * limit,
      })
    ).then(() => {
      setLoader(false);
      setProgress(100);
    });
  }, [limit, currentPage, dispatch, setProgress]);

  // getting the data of tax models from admin state
  const {
    taxModelDetails: { allTaxModels },
  } = useSelector((state) => state.admin);
  const { userPermission } = useSelector((state) => state.admin);
  // console.log(allTaxModels);

  // functions for handling pagination
  const handlePrev = () => {
    let tempCurrentPage = currentPage;
    if (currentPage > 1) {
      tempCurrentPage -= 1;
      setCurrentPage(parseInt(tempCurrentPage));
      navigate(`?page=${tempCurrentPage}`);
      if (tempCurrentPage === 1) {
        navigate(window.location.pathname);
        return;
      }
    }
  };

  const handleNext = () => {
    let tempCurrentPage = currentPage;
    if (tempCurrentPage >= Math.ceil(allTaxModels?.total / limit)) return;
    tempCurrentPage += 1;
    setCurrentPage(parseInt(tempCurrentPage));
    navigate(`?page=${tempCurrentPage}`);
  };

  const handleInputPage = () => {
    if (inputPage < 0 || inputPage === "" || inputPage > Math.ceil(allTaxModels?.total / limit))
      return;
    setCurrentPage(parseInt(inputPage));
    navigate(`?page=${inputPage}`);
  };

  return (
    <DashboardWrapper>
      <h2 align="center">{t("taxModel")}</h2>

      {/* check user permission */}
      {(userPermission.includes("create_tax") || user?.role === "superuser") && (
        <div>
          <Link to="/add-tax-model"> {t("addTaxModel")} </Link>
        </div>
      )}

      {!allTaxModels?.data?.detail && (
        <div className={loader ? "not-appeared" : "appeared"}>
          <div className="cd-tax-models-list-container">
            {allTaxModels &&
              allTaxModels?.tax_models &&
              allTaxModels.tax_models.map((data, index) => (
                <div key={index} className="cd-tax-model-list">
                  <div>
                    {t("title")} : {data?.title}{" "}
                    <span className="cd-err-msg">
                      {data?.is_active === false ? "(Deactivated)" : ""}
                    </span>
                  </div>
                  <div>
                    <Link className="cd-common-anchor-tag" to={`/tax-models/${data?.taxuid}`}>
                      {" "}
                      {t("details")}{" "}
                    </Link>
                  </div>
                </div>
              ))}
          </div>
          <Pagination onClickPrev={handlePrev} onClickNext={handleNext} currentPage={currentPage} />
          <GoToPage
            inputPage={inputPage}
            onClick={handleInputPage}
            onChange={(e) => setInputPage(e.target.value)}
          />
        </div>
      )}
      {allTaxModels?.data?.detail?.detail && (
        <div className="cd-err-msg">{allTaxModels?.data?.detail?.detail[0]?.msg}</div>
      )}
    </DashboardWrapper>
  );
};

export default TaxModels;
