import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { DELETE_COUPON, SEARCH_COUPON } from "../../api";
import axiosFetch from "../../axiosConfig";
import { scrollToTop } from "../../redux/handleUser";
import { getAllCoupons } from "../../redux/slice/backendUserSlice";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Heading2 from "../../components/headings/Heading2";
import GoToPage from "../../components/pagination/GoToPage";
import Pagination from "../../components/pagination/Pagination";
import Button from "../../components/buttons/Button";
import Search from "../../components/search/Search";

const Coupons = ({ setProgress }) => {
  // destructuring translation function from useTranslation hook();
  const { t } = useTranslation();
  // constant to use useDispatch hook
  const dispatch = useDispatch();
  // constant to use useNavigate hook
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [showDeleteWarning, setShowDeleteWarning] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState("");
  const [limit] = useState(10);
  const [loader, setLoader] = useState(true);
  const [searchCouponKeyword, setSearchCouponKeyword] = useState("");
  const [searchCouponResponse, setSearchCouponResponse] = useState();
  const [deleteCouponResponse, setDeleteCouponResponse] = useState("");

  const {
    couponDetails: { allCoupons },
  } = useSelector((state) => state.admin);
  const { userPermission } = useSelector((state) => state.admin);

  // getting all coupons on page render each time
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(
      getAllCoupons({
        limit: limit,
        offset: (currentPage - 1) * limit,
      })
    ).then(() => {
      setProgress(100);
      setLoader(false);
      scrollToTop();
    });
  }, [deleteCouponResponse, limit, currentPage, dispatch, setProgress]);
  // console.log(allCoupons);

  // function to search the coupons
  useEffect(() => {
    if (searchCouponKeyword?.length > 0) {
      const searchCoupon = async () => {
        try {
          const resp = await axiosFetch.get(SEARCH_COUPON + "?coupon_code=" + searchCouponKeyword);
          setSearchCouponResponse(resp.data);
        } catch (error) {
          // console.log(error.response);
        }
      };
      searchCoupon();
    }
  }, [searchCouponKeyword]);

  // function to call the delete coupon API
  const deleteCoupon = async (couponCode) => {
    try {
      const resp = await axiosFetch.delete(DELETE_COUPON + `?coupon_code=${couponCode}`);
      if (resp?.status === 204) {
        setDeleteCouponResponse(t("couponDeleted"));
      }
    } catch (error) {
      setDeleteCouponResponse(error?.response);
    }
  };

  // functions for handling pagination
  const handlePrev = () => {
    let tempCurrentPage = currentPage;
    if (currentPage > 1) {
      tempCurrentPage -= 1;
      setCurrentPage(parseInt(tempCurrentPage));
      navigate(`?page=${tempCurrentPage}`);
      if (tempCurrentPage === 1) {
        navigate(window.location.pathname);
        return;
      }
    }
  };

  const handleNext = () => {
    let tempCurrentPage = currentPage;
    if (tempCurrentPage >= Math.ceil(allCoupons?.total / limit)) return;
    tempCurrentPage += 1;
    setCurrentPage(parseInt(tempCurrentPage));
    navigate(`?page=${tempCurrentPage}`);
  };

  const handleInputPage = () => {
    if (inputPage < 0 || inputPage === "" || inputPage > Math.ceil(allCoupons?.total / limit))
      return;
    setCurrentPage(parseInt(inputPage));
    navigate(`?page=${inputPage}`);
  };

  return (
    <DashboardWrapper>
      <div>
        <Heading2 align="center"> {t("coupon")} </Heading2>
      </div>

      {/* check user permission */}
      {(userPermission.includes("read_coupon") || user?.role === "superuser") && (
        <Search
          value={searchCouponKeyword}
          onChange={(e) => setSearchCouponKeyword(e.target.value)}
          searchResponse={searchCouponResponse?.result}
          placeholder="Enter search keyword"
          clickURL="coupons/update-coupon"
          title="title"
          elemuid="coupon_code"
        />
      )}
      {(userPermission.includes("create_code") || user?.role === "superuser") && (
        <div>
          <Link to="create-coupon">{t("createCoupon")}</Link>
        </div>
      )}
      {!allCoupons?.detail && (
        <div className={loader ? "not-appeared" : "appeared"}>
          <div className="cd-coupons-list-container">
            {allCoupons &&
              allCoupons?.coupons?.map((data, index) => (
                <div className="cd-coupons-list-item" key={index}>
                  <div>
                    {t("title")} : {data?.title}
                  </div>
                  <div>
                    {t("couponCode")} : {data?.coupon_code}
                  </div>
                  <div>
                    {t("percentage")} : {data?.percentage}
                  </div>
                  <div>
                    {t("couponType")} : {data?.coupon_type}
                  </div>
                  {(userPermission.includes("update_coupon") || user?.role === "superuser") && (
                    <div>
                      <Link
                        to={`update-coupon/${encodeURIComponent(encodeURIComponent(data?.coupon_code))}`}
                        className="cd-common-anchor-tag"
                      >
                        {t("update")}
                      </Link>
                    </div>
                  )}
                  {(userPermission.includes("delete_coupon") || user?.role === "superuser") && (
                    <div>
                      <Button
                        onClick={() =>
                          setShowDeleteWarning(showDeleteWarning !== "" ? "" : data?.coupon_code)
                        }
                      >
                        {t("delete")}
                      </Button>
                      {showDeleteWarning === data?.coupon_code && (
                        <div>
                          <div className="cd-err-msg">{t("areYouSureDeleteCoupon")}</div>
                          <Button onClick={() => deleteCoupon(data?.coupon_code)}>
                            {t("yes")}
                          </Button>
                          <Button onClick={() => setShowDeleteWarning("")}>{t("no")}</Button>
                          {deleteCouponResponse?.data?.detail && (
                            <div className="cd-err-msg">
                              {deleteCouponResponse?.data?.detail[0]?.msg}
                            </div>
                          )}
                          {deleteCouponResponse?.data?.detail?.detail && (
                            <div className="cd-err-msg">
                              {deleteCouponResponse?.data?.detail?.detail[0]?.msg}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </div>
          <Pagination onClickPrev={handlePrev} onClickNext={handleNext} currentPage={currentPage} />
          <GoToPage
            inputPage={inputPage}
            onClick={handleInputPage}
            onChange={(e) => setInputPage(e.target.value)}
          />
        </div>
      )}
      {allCoupons?.detail && <div className="cd-err-msg">{allCoupons?.detail?.detail[0].msg}</div>}
    </DashboardWrapper>
  );
};

export default Coupons;
