import React from "react";

const InputField = (props) => (
  <input
    className={props.className}
    id={props.id}
    type={props.type}
    name={props.name}
    placeholder={props.placeholder}
    onChange={props.onChange}
    value={props.value}
    autoComplete="off"
    min={props.min}
    max={props.max}
    checked={props.checked}
  ></input>
);

export default InputField;
