import React from "react";

const TextArea = (props) => (
  <textarea
    className={props.className}
    name={props.name}
    id={props.id}
    cols={props.cols}
    rows={props.rows}
    value={props.value}
    onChange={props.onChange}
  >
    {props.children}
  </textarea>
);

export default TextArea;
