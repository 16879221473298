import React from "react";

const UserRoleHead = (props) => (
  <div>
    <h2 className={props.className} align={props.align}>
      {props.children}
    </h2>
  </div>
);

export default UserRoleHead;
