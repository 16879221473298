import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  GET_ALL_TAX_MODEL_DETAILS,
  UPDATE_TAX_MODEL,
  DELETE_TAX_REGION,
  DELETE_TAX_FIELD,
  ADD_TAX_REGION,
  ADD_TAX_FIELD,
} from "../../api";
import axiosFetch from "../../axiosConfig";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Loader from "../../components/loaders/Loader";
import Heading2 from "../../components/headings/Heading2";
import Button from "../../components/buttons/Button";
import InputField from "../../components/inputs/InputField";

const TaxModelDetails = ({ setProgress }) => {
  // constant to use useTranslation
  const { t } = useTranslation();
  // getting tax uid from URL parameters
  const { tuid } = useParams();
  const navigate = useNavigate();

  const [taxModelDetails, setTaxModelDetails] = useState();
  // loader constant to show seprate loader for each API call
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  // constants used to integrate rename tax model API
  const [showRenameBox, setShowRenameBox] = useState(false);
  const [modelNewName, setModelNewName] = useState("");
  const [taxModelRenameResponse, setTaxModelRenameResponse] = useState("");
  // constants used to integrate delete/deactivate region/field API
  const [showDeactivateWarning, setShowDeactivateWarning] = useState(false);
  const [showRegionDeleteWarning, setShowRegionDeleteWarning] = useState({});
  const [regionDeleteResponse, setRegionDeleteResponse] = useState("");
  const [showFieldDeleteWarning, setShowFieldDeleteWarning] = useState();
  const [fieldDeleteResponse, setFieldDeleteResponse] = useState("");
  // constants used to integrate add regions API
  const [showAddRegionInputs, setShowAddRegionInputs] = useState(false);
  const [regionCountry, setRegionCountry] = useState("");
  const [regionState, setRegionState] = useState("");
  const [addRegionResponse, setAddRegionResponse] = useState();
  // constants used to integrate add field API
  const [showAddFieldInputs, setShowAddFieldInputs] = useState();
  const [fieldTitle, setFieldTitle] = useState();
  const [fieldValue, setFieldValue] = useState();
  const [addFieldResponse, setAddFieldResponse] = useState();

  // get user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  // get user permission from redux state
  const { userPermission } = useSelector((state) => state.admin);

  useEffect(() => {
    setLoader(true);
    setProgress(40);
    const getTaxModelDetails = async () => {
      try {
        const resp = await axiosFetch.get(GET_ALL_TAX_MODEL_DETAILS + tuid);
        setTaxModelDetails(resp?.data);
        setLoader(false);
        setProgress(100);
      } catch (error) {
        setLoader(false);
        setTaxModelDetails(error.response);
        setProgress(100);
      }
    };
    getTaxModelDetails();
  }, [
    taxModelRenameResponse,
    fieldDeleteResponse,
    regionDeleteResponse,
    addRegionResponse,
    addFieldResponse,
  ]);
  // console.log(taxModelDetails);

  // function to update the model name
  const updateModelName = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(UPDATE_TAX_MODEL, {
        tax_id: tuid,
        title: modelNewName,
      });
      if (resp?.status === 201) {
        setTaxModelRenameResponse(resp?.data);
      }
      setLoader2(false);
    } catch (error) {
      setTaxModelRenameResponse(error?.response?.data);
      setLoader2(false);
    }
  };
  // console.log(taxModelRenameResponse);

  // function to call the API of deactivate tax modal
  const handleDeactivateModel = async (value) => {
    try {
      const resp = await axiosFetch.post(UPDATE_TAX_MODEL, {
        tax_id: tuid,
        is_active: value,
      });
      if (resp?.status === 201) {
        navigate("/tax-models");
      }
    } catch (error) {
      // console.log(error?.response);
    }
  };

  // function to handle delete tax regions
  const deleteTaxRegions = async (country, state, index) => {
    try {
      const resp = await axiosFetch.delete(DELETE_TAX_REGION, {
        data: {
          tax_id: tuid,
          country: country,
          state: state,
        },
      });
      if (resp?.status === 200) {
        setRegionDeleteResponse({
          resp: resp?.data,
          index: index,
        });
        // to hide the response msg after 4 sec
        setTimeout(() => {
          setRegionDeleteResponse("");
        }, 4000);
      }
    } catch (error) {
      // console.log(error.response.data);
      setRegionDeleteResponse(error.response.data);
    }
  };
  // console.log(regionDeleteResponse);

  // function to handle delete tax field
  const deleteTaxField = async (title, value, value_type, index) => {
    try {
      const resp = await axiosFetch.delete(DELETE_TAX_FIELD, {
        data: {
          tax_id: tuid,
          title: title,
          value: value,
          value_type: value_type,
        },
      });
      if (resp?.status === 200) {
        setFieldDeleteResponse({
          resp: resp?.data,
          index: index,
        });
      }
    } catch (error) {
      setFieldDeleteResponse(error?.response?.data);
    }
  };

  // function to add a new region
  const handleAddRegion = async () => {
    setLoader3(true);
    try {
      const resp = await axiosFetch.post(ADD_TAX_REGION, {
        tax_id: tuid,
        country: regionCountry,
        state: regionState,
      });
      if (resp?.status === 201) {
        setAddRegionResponse(resp?.data);
        setTimeout(() => {
          setAddRegionResponse("");
        }, 4000);
      }
      setLoader3(false);
    } catch (error) {
      setAddRegionResponse(error.response.data);
      setLoader3(false);
      // console.log(error?.response);
    }
  };

  // function to add a new field
  const handleAddField = async () => {
    setLoader4(true);
    try {
      const resp = await axiosFetch.post(ADD_TAX_FIELD, {
        tax_id: tuid,
        title: fieldTitle,
        value: fieldValue,
        value_type: 0,
      });
      // console.log(resp);
      if (resp?.status === 201) {
        setLoader4(false);
        setAddFieldResponse(resp?.data);
      }
    } catch (error) {
      setLoader4(false);
      setAddFieldResponse(error?.response?.data);
    }
  };
  return (
    <div>
      <DashboardWrapper>
        <div>
          <Heading2 align="center">{t("taxModelDetails")}</Heading2>
        </div>
        <div className={loader ? "not-appeared" : "appeared"}>
          {taxModelDetails?.title && (
            <div>
              <div>
                {t("title")} : {taxModelDetails?.title}
              </div>
              <div className="cd-regions-list-container">
                {t("regions")} -
                {taxModelDetails?.regions.map((data, index) => (
                  <div className="cd-regions-list" key={index}>
                    <div>
                      {t("country")} : {data?.country}
                    </div>
                    <div>
                      {t("state")} : {data?.state}
                    </div>
                    {(userPermission.includes("update_tax") || user?.role === "superuser") && (
                      <div>
                        <Button
                          onClick={() =>
                            setShowRegionDeleteWarning({
                              index: index,
                              state: data?.state,
                            })
                          }
                        >
                          {t("delete")}
                        </Button>
                      </div>
                    )}
                    {showRegionDeleteWarning?.index === index &&
                      showRegionDeleteWarning?.state === data?.state && (
                        <div>
                          <div className="cd-err-msg">{t("areYouSureDeleteRegion")}</div>
                          <Button
                            onClick={() => deleteTaxRegions(data?.country, data?.state, index)}
                          >
                            {t("yes")}
                          </Button>
                          <Button onClick={() => setShowRegionDeleteWarning("")}>{t("no")}</Button>
                        </div>
                      )}
                    {regionDeleteResponse?.resp?.title && regionDeleteResponse?.index === index && (
                      <div className="cd-err-msg">{t("regionDeleted")}</div>
                    )}
                  </div>
                ))}
              </div>
              <div className="cd-fields-list-container">
                {t("fields")} -
                {taxModelDetails?.fields.map((data, index) => (
                  <div className="cd-fields-list" key={index}>
                    <div>
                      {t("title")} : {data?.title}
                    </div>
                    <div>
                      {t("value")} : {data?.value}
                    </div>
                    {(userPermission.includes("update_tax") || user?.role === "superuser") && (
                      <div>
                        <Button
                          onClick={() =>
                            setShowFieldDeleteWarning({
                              index: index,
                              title: data?.title,
                            })
                          }
                        >
                          {t("delete")}
                        </Button>
                      </div>
                    )}
                    {showFieldDeleteWarning?.index === index &&
                      showFieldDeleteWarning?.title === data?.title && (
                        <div>
                          <div className="cd-err-msg">{t("areYouSureDeleteField")}</div>
                          <Button
                            onClick={() =>
                              deleteTaxField(data?.title, data?.value, data?.value_type, index)
                            }
                          >
                            {t("yes")}
                          </Button>
                          <Button onClick={() => setShowFieldDeleteWarning("")}>{t("no")}</Button>
                        </div>
                      )}
                    {fieldDeleteResponse?.resp?.title && fieldDeleteResponse?.index === index && (
                      <div className="cd-err-msg">Field deleted</div>
                    )}
                  </div>
                ))}
              </div>
              <div>
                {(userPermission.includes("update_tax") || user?.role === "superuser") && (
                  <Button className="cd-btn" onClick={() => setShowRenameBox(!showRenameBox)}>
                    {t("rename")}
                  </Button>
                )}
                {showRenameBox && (
                  <div>
                    <div className="cd-input-container">
                      <label htmlFor="" className="cd-input-label">
                        {t("title")} :
                      </label>
                      <InputField
                        className="cd-input-field"
                        name="cd_tax_model_title"
                        placeholder={t("placeholderTitle")}
                        value={modelNewName}
                        onChange={(e) => setModelNewName(e.target.value)}
                      ></InputField>
                    </div>

                    {/* code block to show the success msg conditionally */}
                    {taxModelRenameResponse?.title && (
                      <div className="cd-success-msg">{t("taxModelRenamedMsg")}</div>
                    )}

                    {/* code block to show the error msg conditonally */}
                    {taxModelRenameResponse?.detail && (
                      <div className="cd-err-msg">{taxModelRenameResponse?.detail[0]?.msg}</div>
                    )}
                    {taxModelRenameResponse?.detail?.detail && (
                      <div className="cd-err-msg">
                        {taxModelRenameResponse?.detail?.detail[0]?.msg}
                      </div>
                    )}

                    {/* code block to show the loader conditionally */}
                    {loader2 && <Loader />}
                    <Button
                      className="cd-btn"
                      onClick={updateModelName}
                      disabled={modelNewName.length > 0 ? false : true}
                    >
                      {t("update")}
                    </Button>
                  </div>
                )}
              </div>
              <div>
                {(userPermission.includes("update_tax") || user?.role === "superuser") && (
                  <Button
                    className="cd-btn"
                    onClick={() => setShowAddRegionInputs(!showAddRegionInputs)}
                  >
                    {t("addRegion")}
                  </Button>
                )}
                {showAddRegionInputs && (
                  <div>
                    <div className="cd-input-container">
                      <label className="cd-input-label">{t("country")} : </label>
                      <InputField
                        type="text"
                        className="cd-input-field"
                        name="cd_tax_model_country"
                        placeholder={t("placeholderCountry")}
                        onChange={(e) => setRegionCountry(e.target.value)}
                      ></InputField>
                    </div>
                    <div className="cd-input-container">
                      <label className="cd-input-label">{t("state")} : </label>
                      <InputField
                        type="text"
                        className="cd-input-field"
                        name="cd_tax_model_state"
                        placeholder={t("placeholderState")}
                        onChange={(e) => setRegionState(e.target.value)}
                      ></InputField>
                    </div>
                    {addRegionResponse?.title && (
                      <div className="cd-success-msg">{t("regionAddedMsg")}</div>
                    )}
                    {addRegionResponse?.detail && (
                      <div className="cd-err-msg">{addRegionResponse?.detail[0]?.msg}</div>
                    )}
                    {addRegionResponse?.detail?.detail && (
                      <div className="cd-err-msg">{addRegionResponse?.detail?.detail[0]?.msg}</div>
                    )}
                    {loader3 && <Loader />}
                    <Button className="cd-btn" onClick={handleAddRegion}>
                      {t("add")}
                    </Button>
                  </div>
                )}
              </div>
              <div>
                {(userPermission.includes("update_tax") || user?.role === "superuser") && (
                  <Button
                    className="cd-btn"
                    onClick={() => setShowAddFieldInputs(!showAddFieldInputs)}
                  >
                    {t("addField")}
                  </Button>
                )}
                {showAddFieldInputs && (
                  <div>
                    <div className="cd-input-container">
                      <label className="cd-input-label">{t("title")} :</label>
                      <InputField
                        placeholder={t("placeholderTitle")}
                        className="cd-input-field"
                        name="cd_tax_model_field_title"
                        onChange={(e) => setFieldTitle(e.target.value)}
                      ></InputField>
                    </div>
                    <div className="cd-input-container">
                      <label className="cd-input-label">{t("value")} :</label>
                      <InputField
                        type="number"
                        placeholder={t("placeholderValue")}
                        className="cd-input-field"
                        name="cd_tax_model_field_value"
                        onChange={(e) => setFieldValue(e.target.value)}
                      ></InputField>
                    </div>
                    {loader4 && <Loader />}
                    {addFieldResponse?.title && (
                      <div className="cd-success-msg">{t("fieldAddedMsg")}</div>
                    )}
                    {addFieldResponse?.detail && (
                      <div className="cd-err-msg">{addFieldResponse?.detail[0]?.msg}</div>
                    )}
                    {addFieldResponse?.detail?.detail && (
                      <div className="cd-err-msg">{addFieldResponse?.detail?.detail[0]?.msg}</div>
                    )}
                    <Button className="cd-btn" onClick={handleAddField}>
                      {t("add")}
                    </Button>
                  </div>
                )}
              </div>
              {(userPermission.includes("update_tax") || user?.role === "superuser") &&
                taxModelDetails?.is_active === true && (
                  <div>
                    <Button
                      className="cd-btn"
                      onClick={() => setShowDeactivateWarning(!showDeactivateWarning)}
                    >
                      {t("deactivate")}
                    </Button>
                    {showDeactivateWarning && (
                      <div>
                        <div className="cd-err-msg">{t("areYouSureDeactivateModel")}</div>
                        <div>
                          <Button onClick={() => handleDeactivateModel("false")}>{t("yes")}</Button>
                          <Button onClick={() => setShowDeactivateWarning(!showDeactivateWarning)}>
                            {t("no")}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              {(userPermission.includes("update_tax") || user?.role === "superuser") &&
                taxModelDetails?.is_active === false && (
                  <div>
                    <Button className="cd-btn" onClick={() => handleDeactivateModel("true")}>
                      {t("activate")}
                    </Button>
                  </div>
                )}
            </div>
          )}
        </div>
        {taxModelDetails?.data?.detail && (
          <div className="cd-err-msg">{taxModelDetails?.data?.detail[0]?.msg}</div>
        )}
        {taxModelDetails?.data?.detail?.detail && (
          <div className="cd-err-msg">{taxModelDetails?.data?.detail?.detail[0]?.msg}</div>
        )}
      </DashboardWrapper>
    </div>
  );
};

export default TaxModelDetails;
