import React from "react";
import { Link, Navigate } from "react-router-dom";

const Homepage = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <div align="center">
      <div>
        <img src="/company-logo.jpg" alt="" />
      </div>
      <div>
        <h2>Welcome to Code CMS</h2>
        <div>
          Please{" "}
          <Link className="cd-common-anchor-tag" to="/login">
            login
          </Link>{" "}
          to continue
        </div>
      </div>
    </div>
  );
};

export default Homepage;
