import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import axiosFetch from "../../axiosConfig";
import { ADD_TAX_MODEL } from "../../api";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import InputField from "../../components/inputs/InputField";
import Heading2 from "../../components/headings/Heading2";
import { Card1 } from "../../components/commoncomps/Card1";
import Button from "../../components/buttons/Button";
import Loader from "../../components/loaders/Loader";

const AddTaxModel = () => {
  // constant to use useTranslation hook
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [modelTitle, setModelTitle] = useState("");
  const [numberOfRegions, setNumberOfRegions] = useState(["1"]);
  const [numberOfFields, setNumberOfFields] = useState(["1"]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [fieldTitle, setFieldTitle] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [regionsList, setRegionsList] = useState([]);
  const [fieldsList, setFieldsList] = useState([]);
  const [addTaxModelResponse, setAddTaxModelResponse] = useState();

  // get user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  // get user permission from admin state
  const { userPermission } = useSelector((state) => state.admin);

  // function to add more input field to enter regions
  const addRegions = () => {
    const currentNumberOfRegions = [...numberOfRegions];
    currentNumberOfRegions.push("1");
    setNumberOfRegions(currentNumberOfRegions);
  };

  // function to add more input field to enter regions
  const addFields = () => {
    const currentNumberOfFields = [...numberOfFields];
    currentNumberOfFields.push("1");
    setNumberOfFields(currentNumberOfFields);
  };

  // function to receive the value of country and regions
  const handleRegions = (type, value, index) => {
    const region = {
      country,
      state,
    };
    if (type === "country") {
      region.country = value;
      setCountry(value);
    }
    if (type === "state") {
      region.state = value;
      setState(value);
    }
    const tempRegionList = [...regionsList];
    tempRegionList[index] = region;
    setRegionsList(tempRegionList);
  };

  // function to receive value of feature title and value
  const handleField = (type, value, index) => {
    const field = {
      title: fieldTitle,
      value: fieldValue,
      value_type: "1",
    };
    if (type === "title") {
      field.title = value;
      setFieldTitle(value);
    }
    if (type === "value") {
      field.value = value;
      setFieldValue(value);
    }
    const tempFieldsList = [...fieldsList];
    tempFieldsList[index] = field;
    setFieldsList(tempFieldsList);
  };

  const addTaxModelPayload = {
    title: modelTitle,
    regions: regionsList,
    fields: fieldsList,
  };

  // function to submit and call the add tax model API
  const addTaxModel = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(ADD_TAX_MODEL, addTaxModelPayload);
      if (resp?.status === 201) {
        setAddTaxModelResponse(resp?.data);
      }
      setLoader(false);
    } catch (error) {
      setAddTaxModelResponse(error?.response?.data);
      setLoader(false);
    }
  };

  // check the user permission
  if (!userPermission.includes("create_tax") && user?.role !== "superuser") {
    return (
      <DashboardWrapper>
        <div className="cd-err-msg">You don't have access to view this page</div>
      </DashboardWrapper>
    );
  }
  return (
    <div>
      <DashboardWrapper>
        <div>
          <Heading2 align="center">{t("addTaxModel")}</Heading2>
        </div>
        <Card1>
          <div>
            <div className="cd-input-container">
              <label className="cd-input-label">{t("title")} : </label>
              <InputField
                type="text"
                className="cd-input-field"
                name="cd_tax_model_title"
                placeholder={t("placeholderTitle")}
                onChange={(e) => setModelTitle(e.target.value)}
              ></InputField>
            </div>
            <div className="cd-input-container">
              <div>{t("regions")} :</div>
              <Button className="cd-btn" onClick={addRegions}>
                {t("addMoreRegion")}
              </Button>
              {numberOfRegions?.map((data, index) => (
                <div key={index}>
                  <div className="cd-input-container">
                    <label className="cd-input-label">
                      {index + 1}. {t("country")} :{" "}
                    </label>
                    <InputField
                      type="text"
                      name="cd_tax_model_country"
                      className="cd-input-field"
                      placeholder={t("placeholderCountry")}
                      onChange={(e) => handleRegions("country", e.target.value, index)}
                    ></InputField>
                  </div>
                  <div className="cd-input-container">
                    <label className="cd-input-label">{t("state")} : </label>
                    <InputField
                      type="text"
                      className="cd-input-field"
                      name="cd_tax_model_state"
                      placeholder={t("placeholderState")}
                      onChange={(e) => handleRegions("state", e.target.value, index)}
                    ></InputField>
                  </div>
                </div>
              ))}
            </div>
            <div className="cd-input-container">
              <div>{t("fields")} :</div>
              <Button className="cd-btn" onClick={addFields}>
                {t("addMoreField")}
              </Button>
              {numberOfFields?.map((data, index) => (
                <div key={index}>
                  <div className="cd-input-container">
                    <label className="cd-input-label">
                      {index + 1}. {t("title")} :{" "}
                    </label>
                    <InputField
                      type="text"
                      name="cd_tax_model_field"
                      className="cd-input-field"
                      placeholder={t("placeholderTitle")}
                      onChange={(e) => handleField("title", e.target.value, index)}
                    ></InputField>
                  </div>
                  <div className="cd-input-container">
                    <label className="cd-input-label">{t("value")} : </label>
                    <InputField
                      type="number"
                      name="cd_tax_model_field_value"
                      className="cd-input-field"
                      placeholder={t("placeholderValue")}
                      onChange={(e) => handleField("value", e.target.value, index)}
                    ></InputField>
                  </div>
                </div>
              ))}
            </div>
            {loader && <Loader />}
            {addTaxModelResponse?.title && (
              <div className="cd-success-msg">{t("taxModelAddedMsg")}</div>
            )}
            {addTaxModelResponse?.detail && (
              <div className="cd-err-msg">{addTaxModelResponse?.detail[0]?.msg}</div>
            )}
            {addTaxModelResponse?.detail?.detail && (
              <div className="cd-err-msg">{addTaxModelResponse?.detail?.detail[0]?.msg}</div>
            )}
            <div>
              <Button onClick={addTaxModel} className="cd-btn">
                {t("add")}
              </Button>
            </div>
          </div>
        </Card1>
      </DashboardWrapper>
    </div>
  );
};

export default AddTaxModel;
