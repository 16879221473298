import React from "react";

const Loader = (props) => (
  <div>
    <div id="loader" className="cd-loader"></div>
    <div className="cd-loader_content">{props.children}</div>
  </div>
);

export default Loader;
