import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import axiosFetch from "../../axiosConfig";
import { GET_FRONTENDUSER_DETAIL, UPDATE_FRONTEND_USER } from "../../api";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import UserRoleHead from "../../components/headings/Heading2";
import Loader from "../../components/loaders/Loader";
import Button from "../../components/buttons/Button";

const FrontEndUserDetails = ({ setProgress }) => {
  const { uuid } = useParams();

  // contant to use useTranslation
  const { t } = useTranslation();

  // get user from localstorage
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [userDetail, setUserDetail] = useState("");
  const [deactivateFrontEndUser, setDeactivateFrontEndUser] = useState(true);
  const [showDeactivateWarning, setShowDeactivateWarning] = useState(false);
  const [apiResponse, setApiResponse] = useState();
  const [restoreResponse, setRestoreResponse] = useState();

  // get user permission from redux state
  const { userPermission } = useSelector((state) => state.admin);

  // to get current user information on the basis of uuid
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    const getUserDetails = async () => {
      try {
        const resp = await axiosFetch.get(GET_FRONTENDUSER_DETAIL + uuid);
        if (resp?.status === 200) {
          setUserDetail(resp?.data);
          setProgress(100);
          setLoader(false);
          setDeactivateFrontEndUser(resp?.data?.is_active);
        }
      } catch (error) {
        setLoader(false);
        setProgress(100);
        // console.log(error?.response);
      }
    };
    getUserDetails();
  }, [uuid]);

  // update front end user
  const updateFrontEndUser = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(UPDATE_FRONTEND_USER, {
        user_id: uuid,
        is_active: deactivateFrontEndUser,
        is_deleted: false,
      });
      if (resp?.status === 200) {
        setApiResponse(resp?.data);
        setLoader(false);
        setTimeout(() => {
          navigate("/frontend-users");
        }, 1000);
      }
    } catch (error) {
      setApiResponse(error?.response);
      setLoader(false);
    }
  };
  // console.log(apiResponse);

  // to restore or ractivate front end user
  const handleRestoreUser = async (param) => {
    try {
      const resp = await axiosFetch.post(UPDATE_FRONTEND_USER, {
        user_id: uuid,
        is_active: param === "activate" ? true : deactivateFrontEndUser,
        is_deleted: false,
      });
      if (resp?.status === 200) {
        setRestoreResponse(resp?.data);
        setTimeout(() => {
          navigate("/frontend-users");
        }, 1000);
      }
    } catch (error) {
      setRestoreResponse(error?.response);
      setLoader(false);
    }
  };
  return (
    <div>
      <DashboardWrapper>
        <UserRoleHead align="center">{t("userProfile")}</UserRoleHead>
        <div className={loader ? "not-appeared" : "appeared"}>
          <div className="cd-user-profile-photo-container">
            <img
              src={`https://codecms.codeholic.in/static/uploads/${userDetail?.profile_photo}`}
              alt=""
              className="cd-user-profile-photo"
            />
          </div>
          <div>
            {t("name")} : {userDetail?.first_name + " " + userDetail?.last_name}
          </div>
          <div>
            {t("email")} : {userDetail?.email}
          </div>
          <div>
            {t("username")} : {userDetail?.username}
          </div>
          <div>
            {t("language")} : {userDetail?.language}
          </div>
          <div>
            {t("timezone")} : {userDetail?.timezone}
          </div>
          {/* code block the activate the deactivate option */}
          {userDetail?.is_active ? (
            <div>
              {(userPermission.includes("update_user") || user?.role === "superuser") && (
                <div>
                  <span
                    style={{ cursor: "pointer" }}
                    className="cd-err-msg"
                    onClick={(e) => setShowDeactivateWarning(!showDeactivateWarning)}
                  >
                    {t("deactivateUser")}
                  </span>
                </div>
              )}
              {showDeactivateWarning && (
                <div>
                  <div>{t("areYouSureDeactivateUser")}</div>
                  <div>
                    <span
                      style={{ cursor: "pointer" }}
                      className={`cd-err-msg ${deactivateFrontEndUser === false ? "cd-border" : ""}`}
                      onClick={(e) => setDeactivateFrontEndUser(!deactivateFrontEndUser)}
                    >
                      {t("yes")}
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      className="cd-success-msg"
                      onClick={(e) => {
                        setDeactivateFrontEndUser(true);
                        setShowDeactivateWarning(false);
                      }}
                    >
                      {" "}
                      {t("no")}
                    </span>
                  </div>
                  <div>
                    <Button className="cd-btn" onClick={updateFrontEndUser}>
                      {t("update")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            (userPermission?.includes("update_user") || user?.role === "superuser") && (
              <div>
                <span
                  className="cd-success-msg cd-btn"
                  onClick={(e) => handleRestoreUser("activate")}
                >
                  {t("activateUser")}
                </span>
              </div>
            )
          )}

          {/* code block to show the loader */}
          {loader && <Loader />}
          {apiResponse?.uuid && (
            <div className="cd-success-msg">{t("userUpdated")}! Redirecting...</div>
          )}

          {/* code block to api response error */}
          {apiResponse?.data?.detail && (
            <div className="cd-err-msg">{apiResponse?.data?.detail[0]?.msg}</div>
          )}
          {apiResponse?.data?.detail?.detail && (
            <div className="cd-err-msg">{apiResponse?.data?.detail?.detail[0]?.msg}</div>
          )}

          {/* code block to show the restore or activate user response */}
          {restoreResponse?.uuid && (
            <div className="cd-success-msg">
              {restoreResponse?.is_active === true && `${t("userUpdated")}! Redirecting...`}
            </div>
          )}
        </div>
      </DashboardWrapper>
    </div>
  );
};

export default FrontEndUserDetails;
