import React, { useState } from "react";

import axiosFetch from "../../axiosConfig";
import { Card1 } from "../../components/commoncomps/Card1";
import { SEND_EMAIL_TOKEN } from "../../api";
import InputEmail from "../../components/inputs/InputEmail";
import Button from "../../components/buttons/Button";
import Loader from "../../components/loaders/Loader";

const SendToken = () => {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");
  const [loader, setLoader] = useState(false);
  const isDisabled = email.length < 0 ? true : false;
  const sendEmailToken = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.get(SEND_EMAIL_TOKEN + `?email=${email}`, {});
      setResponse(resp);
      if (resp?.status == 200) setLoader(false);
    } catch (error) {
      setResponse(error.response);
      if (error?.response?.data) setLoader(false);
    }
  };
  return (
    <Card1>
      <div>
        <h2 className="cd-user-role-head">Send Verification Token</h2>
      </div>
      <div className="cd-input-container">
        <label htmlFor="" className="cd-input-label">
          Email :
        </label>
        <InputEmail
          className="cd-input-field"
          placeholder="Enter your email"
          name="cd_user_email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      {response?.status === 200 && <div className="cd-success-msg">Token Sent Successfully</div>}
      {response?.status === 403 && (
        <div className="cd-err-msg">{response?.data?.detail?.detail[0]?.msg}</div>
      )}
      {loader && <Loader />}
      <div>
        <Button onClick={sendEmailToken} className="cd-btn" disabled={isDisabled}>
          Send
        </Button>
      </div>
    </Card1>
  );
};

export default SendToken;
