import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axiosFetch from "../../axiosConfig";
import { GET_USER, UPDATE_BACKEND_USER } from "../../api";
import { getAllRoles } from "../../redux/slice/backendUserSlice";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import UserRoleHead from "../../components/headings/Heading2";
import Button from "../../components/buttons/Button";
import Modal from "../../components/commoncomps/Modal";
import Loader2 from "../../components/loaders/Loader2";

const UserDetails = ({ setProgress }) => {
  const { uuid } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false);
  const [updateUserResponse, setUpdateUserResponse] = useState("");
  const [deactivateBackEndUser, setDeactivateBackEndUser] = useState(true);

  // constant to use useDispatch() hook
  const dispatch = useDispatch();

  // get user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  // to get current user info
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    const getUserInfo = async () => {
      try {
        const resp = await axiosFetch.get(GET_USER + `/${uuid}`);
        setUserInfo(resp.data);
        setSelectedRole(resp.data.role.ruid);
        setProgress(100);
        setLoader(false);
      } catch (error) {
        setUserInfo(error.response);
        setProgress(100);
        setLoader(false);
      }
    };
    getUserInfo();
  }, [uuid, updateUserResponse]);

  // get user permission from redux state
  const { userPermission } = useSelector((state) => state.admin);
  //getting all roles from admin state
  const {
    rolesData: { allRoles },
  } = useSelector((state) => state.admin);

  // dispatch the action to get all the roles
  useEffect(() => {
    // prevent api call if data available
    if (allRoles.length === 0) {
      dispatch(getAllRoles()).then(() => {
        setLoader2(false);
      });
    }
  }, []);

  // to update the user
  const updateUser = async (value) => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(UPDATE_BACKEND_USER, {
        user_id: userInfo?.uuid,
        role_id: selectedRole,
        is_active: deactivateBackEndUser,
        is_deleted: false,
      });
      if (resp.status === 200) {
        setLoader2(false);
        if (deactivateBackEndUser !== userInfo?.is_active)
          setTimeout(() => {
            navigate("/users");
          }, 2000);
      }
      setUpdateUserResponse(resp);
    } catch (error) {
      setUpdateUserResponse(error?.resp);
      setLoader2(false);
    }
  };
  return (
    <DashboardWrapper>
      <UserRoleHead align="center">{t("userProfile")}</UserRoleHead>
      <div className={`cd-backend-users-list-container ${loader ? "not-appeared" : "appeared"}`}>
        <div className="cd-backend-users-list-element">
          <div className="cd_backend_user_username">
            {t("username")} : {userInfo?.username}
          </div>
          <div>
            {t("uniqueID")} : {userInfo?.uuid}
          </div>
          {userInfo?.email_verified_at !== null && (
            <div className="cd_backend_user_email">
              {t("email")} : {userInfo?.email}
            </div>
          )}
          {userInfo?.email_verified_at == null && (
            <div className="cd-err-msg">
              {t("email")} : {userInfo?.email}(not verified)
            </div>
          )}
          <div className="cd_backend_user_role">
            {t("role")} : {userInfo?.role?.title}
            {userInfo?.role?.title !== "superuser" &&
              (userPermission.includes("update_user") || user.role === "superuser") && (
                <span
                  className="cd-success-msg"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowUpdateRoleModal(!showUpdateRoleModal);
                    setSelectedRole(userInfo?.role?.ruid);
                    setDeactivateBackEndUser(true);
                  }}
                >
                  {" "}
                  {t("update")}
                </span>
              )}
          </div>
          {showUpdateRoleModal && (
            <Modal handleModal={() => setShowUpdateRoleModal(false)}>
              <UserRoleHead align="center">Update Role</UserRoleHead>
              <div>
                <select
                  className="cd-input-field"
                  name="roleSelect"
                  id="roleSelect"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  <option value="">Select role</option>
                  {allRoles?.map((role) => (
                    <option key={role.ruid} value={role.ruid}>
                      {role.title}
                    </option>
                  ))}
                </select>
              </div>
              <Button className="cd-btn" onClick={updateUser}>
                {t("update")} {loader2 && <Loader2 />}
              </Button>
              {updateUserResponse?.status === 200 && (
                <div className="cd-success-msg">User Updated!</div>
              )}
            </Modal>
          )}
          {userInfo?.is_active === true && userInfo?.role?.title !== "superuser" && (
            <div style={{ cursor: "pointer" }}>
              {(userPermission.includes("delete_user") || user?.role === "superuser") && (
                <span
                  className="cd-err-msg"
                  onClick={() => {
                    setShowWarning(!showWarning);
                    setSelectedRole(userInfo?.role?.ruid);
                  }}
                >
                  {t("deactivate")}
                </span>
              )}
              {showWarning && (
                <>
                  <div>{t("areYouSureDeactivateUser")}</div>
                  <span
                    className={`cd-err-msg ${deactivateBackEndUser === false ? "cd-border" : ""}`}
                    onClick={() => setDeactivateBackEndUser(!deactivateBackEndUser)}
                  >
                    {t("yes")}
                  </span>
                  <span
                    className="cd-success-msg"
                    onClick={() => {
                      setDeactivateBackEndUser(true);
                      setShowWarning(!showWarning);
                    }}
                  >
                    {t(" ")}
                    {t("no")}
                  </span>
                  <div>
                    <Button className="cd-btn" onClick={updateUser}>
                      {t("update")}
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
          {(userPermission.includes("delete_user") || user?.role === "superuser") &&
            userInfo?.is_active === false && (
              <div
                className="cd-success-msg"
                style={{ cursor: "pointer" }}
                onClick={() => updateUser(!userInfo?.is_active)}
              >
                Activate
              </div>
            )}
          {updateUserResponse?.status === 200 && (
            <div className="cd-success-msg">User Updated!</div>
          )}
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default UserDetails;
