import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import axiosFetch from "../../axiosConfig";
import { ADD_SUBSCRIPTION_ENDPONT } from "../../api";
import { getAllFeatures } from "../../redux/slice/backendUserSlice";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import { Card1 } from "../../components/commoncomps/Card1";
import InputField from "../../components/inputs/InputField";
import TextArea from "../../components/inputs/TextArea";
import Button from "../../components/buttons/Button";
import Loader from "../../components/loaders/Loader";

const AddSubscription = () => {
  const dispatch = useDispatch();

  // contant to use useTranslation
  const { t } = useTranslation();

  // get user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  const [subscriptionName, setSubscriptionName] = useState("");
  const [subscriptionDescription, setSubscriptionDescription] = useState("");
  const [subscriptionPriceINR, setSubscriptionPriceINR] = useState("");
  const [subscriptionPriceUSD, setSubscriptionPriceUSD] = useState("");
  const [subscriptionPricePound, setSubscriptionPricePound] = useState("");
  const [subscriptionPriceEuro, setSubscriptionPriceEuro] = useState("");
  const [subscriptionSalePriceINR, setSubscriptionSalePriceINR] = useState("");
  const [subscriptionSalePriceUSD, setSubscriptionSalePriceUSD] = useState("");
  const [subscriptionSalePricePound, setSubscriptionSalePricePound] = useState("");
  const [subscriptionSalePriceEuro, setSubscriptionSalePriceEuro] = useState("");
  const [subscriptionValidity, setSubscriptionValidity] = useState("");
  const [submitSubscriptionResponse, setSubmitSubscriptionResponse] = useState();
  const [loader, setLoader] = useState(false);
  const [featureQuantity, setFeatureQuantity] = useState(parseInt("1"));
  const [featureCode, setFeatureCode] = useState();
  const [featureSelectorsCount, setFeatureSelectorsCount] = useState(["element"]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const subscriptionDetails = {
    title: subscriptionName,
    description: subscriptionDescription,
    price_inr: subscriptionPriceINR,
    sale_price_inr: subscriptionSalePriceINR,
    price_usd: subscriptionPriceUSD,
    sale_price_usd: subscriptionSalePriceUSD,
    price_pond: subscriptionPricePound,
    sale_price_pond: subscriptionSalePricePound,
    price_euro: subscriptionPriceEuro,
    sale_price_euro: subscriptionSalePriceEuro,
    validity: subscriptionValidity,
    features: selectedFeatures,
  };

  // getting all features
  useEffect(() => {
    if (allFeatures.length == 0) {
      dispatch(getAllFeatures());
    }
  }, []);

  // get all features from admin state
  const {
    featuresDetail: { allFeatures },
  } = useSelector((state) => state.admin);

  // get user permission from admin state
  const { userPermission } = useSelector((state) => state.admin);

  const isDisabled = !!(
    subscriptionName.length === 0 ||
    subscriptionPriceINR.length === 0 ||
    subscriptionValidity.length === 0 ||
    selectedFeatures.length === 0
  );

  // submitting the subscription to backend
  const submitSubscription = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(ADD_SUBSCRIPTION_ENDPONT, subscriptionDetails);
      setSubmitSubscriptionResponse(resp);
      if (resp) setLoader(false);
    } catch (error) {
      setSubmitSubscriptionResponse(error?.response);
      setLoader(false);
    }
  };

  // to increment the number of featureSelect input
  const incrementFeatureSelect = () => {
    // to stop user to add more featureSelect if user select all features
    if (allFeatures.length === featureSelectorsCount.length) return;
    if (selectedFeatures.length == 0) return;
    setFeatureSelectorsCount([...featureSelectorsCount, "element"]);
  };

  const addFeature = (index, value) => {
    const featureObject = {
      feature_code: featureCode,
      quantity: featureQuantity,
    };
    if (typeof value !== "number") {
      featureObject.feature_code = value;
      setFeatureCode(value);
    } else {
      featureObject.quantity = value;
      setFeatureQuantity(value);
    }

    const updatedSelectedFeature = [...selectedFeatures];
    updatedSelectedFeature[index] = featureObject;
    setSelectedFeatures(updatedSelectedFeature);
  };

  // check the user permission
  if (!userPermission.includes("create_subscription") && user?.role !== "superuser") {
    return (
      <DashboardWrapper>
        <div className="cd-err-msg">You don't have access to view this page</div>
      </DashboardWrapper>
    );
  }

  return (
    <div>
      <DashboardWrapper>
        <div>
          <h2 align="center">{t("addSubscription")}</h2>
        </div>
        <Card1>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("name")}:
            </label>
            <InputField
              className="cd-input-field"
              name="cd_subscription_name"
              type="text"
              placeholder={t("subscription") + " " + t("name")}
              onChange={(e) => setSubscriptionName(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(INR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_price_inr"
              placeholder={t("placeholderPrice")}
              onChange={(e) => setSubscriptionPriceINR(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")} (INR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_sale_price_inr"
              placeholder={t("placeholderSalePrice")}
              onChange={(e) => setSubscriptionSalePriceINR(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(USD):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_price_usd"
              placeholder={t("placeholderPrice")}
              onChange={(e) => setSubscriptionPriceUSD(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")}(USD):
            </label>
            <InputField
              className="cd-input-field"
              name="cd_subscription_sale_price_usd"
              type="number"
              placeholder={t("placeholderSalePrice")}
              onChange={(e) => setSubscriptionSalePriceUSD(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(GBP):
            </label>
            <InputField
              className="cd-input-field"
              name="cd_subscription_price_gbp"
              type="number"
              placeholder={t("placeholderPrice")}
              onChange={(e) => setSubscriptionPricePound(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")} (GBP):
            </label>
            <InputField
              className="cd-input-field"
              name="cd_subscription_sale_price_gbp"
              type="number"
              placeholder={t("placeholderSalePrice")}
              onChange={(e) => setSubscriptionSalePricePound(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(EUR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_price_eur"
              placeholder={t("placeholderPrice")}
              onChange={(e) => setSubscriptionPriceEuro(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")}(EUR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_sale_price_gbp"
              placeholder={t("placeholderSalePrice")}
              onChange={(e) => setSubscriptionSalePriceEuro(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("validity")}(In Days):
            </label>
            <InputField
              className="cd-input-field"
              min="0"
              type="number"
              name="cd_subscription_validity"
              placeholder={t("placeholderValidity")}
              onChange={(e) => setSubscriptionValidity(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("details")}:
            </label>
            <TextArea
              className="cd_input_textarea"
              cols="24"
              rows="10"
              name="cd_subscription_desc"
              onChange={(e) => setSubscriptionDescription(e.target.value)}
            ></TextArea>
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("feature")}:
              <Button onClick={incrementFeatureSelect} className="cd-btn">
                +
              </Button>
            </label>
            {featureSelectorsCount?.map((count, index) => (
              <div key={index} className="cd-features-list-selector clearfix">
                <select
                  className="cd-features-list-selector-left cd-input-field"
                  name="features_select"
                  id=""
                  onChange={(e) => addFeature(index, e.target.value)}
                >
                  <option value="">{t("selectFeature")}</option>
                  {allFeatures.map((feature, index) => (
                    <option key={index} value={feature?.feature_code}>
                      {feature?.feature_type}
                    </option>
                  ))}
                </select>
                <InputField
                  className="cd-input-field cd-features-list-selector-right"
                  type="number"
                  name="cd_feature_quantity"
                  placeholder="Quantity"
                  min="1"
                  onChange={(e) => addFeature(index, parseInt(e.target.value))}
                ></InputField>
              </div>
            ))}
          </div>
          {loader && <Loader />}
          {submitSubscriptionResponse?.status == 201 && (
            <div className="cd-success-msg">{t("subscriptionAddedMsg")}</div>
          )}
          {submitSubscriptionResponse?.data?.detail && (
            <div className="">
              <div className="cd-err-msg">{submitSubscriptionResponse?.data?.detail[0]?.msg}</div>
            </div>
          )}
          {submitSubscriptionResponse?.data?.detail?.detail && (
            <div className="">
              <div className="cd-err-msg">
                {submitSubscriptionResponse?.data?.detail?.detail[0]?.msg}
              </div>
            </div>
          )}
          <div>
            <Button
              className="cd-btn"
              onClick={submitSubscription}
              disabled={isDisabled}
              title={isDisabled ? "Please fill all inputs to enable" : "Submit"}
            >
              Add Subscription
            </Button>
          </div>
        </Card1>
      </DashboardWrapper>
    </div>
  );
};

export default AddSubscription;
