import React from "react";

const Button = (props) => (
  <div className="cd-btn-container">
    <button
      id={props.id}
      onClick={props.onClick}
      className={props.className}
      type={props.type}
      disabled={props.disabled}
      title={props.title}
    >
      {props.children}
    </button>
  </div>
);

export default Button;
