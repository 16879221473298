import { React, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { GET_SUBSCRIPTION_DETAIL, DEACTIVATE_SUBSCRIPTION_ENDPOINT } from "../../api";
import axiosFetch from "../../axiosConfig";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Button from "../../components/buttons/Button";
import Loader from "../../components/loaders/Loader";
import Loader2 from "../../components/loaders/Loader2";

const SubscriptionDetail = ({ setProgress }) => {
  const navigate = useNavigate();

  // contant to use useTranslation
  const { t } = useTranslation();

  // get user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  const { suid } = useParams();
  const [response, setResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();
  const [restoreResponse, setRestoreResponse] = useState();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  // get user permission from admin state
  const { userPermission } = useSelector((state) => state.admin);

  // getting the subscription detail
  useEffect(() => {
    setLoader(true);
    setProgress(40);
    const getSubscriptionDetail = async () => {
      try {
        const resp = await axiosFetch.get(GET_SUBSCRIPTION_DETAIL + suid);
        setResponse(resp);
        setProgress(100);
        setLoader(false);
      } catch (error) {
        setResponse(error?.response);
        setLoader(false);
        setProgress(100);
      }
    };
    getSubscriptionDetail();
  }, [suid]);
  // console.log(response);

  // to delete the subscription
  const deactivateSubscription = async () => {
    setShowConfirmDelete(false);
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(
        DEACTIVATE_SUBSCRIPTION_ENDPOINT + `/${suid}?is_active=false`
      );
      setDeleteResponse(resp);
      if (resp?.status == 200) {
        setLoader2(false);
        setTimeout(() => {
          navigate("/subscriptions");
        }, 3000);
      }
    } catch (error) {
      setDeleteResponse(error?.response);
      setLoader2(false);
    }
  };
  // console.log(deleteResponse);

  // to restore the subscription
  const restoreSubscription = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(
        DEACTIVATE_SUBSCRIPTION_ENDPOINT + `/${suid}?is_active=true`
      );
      setRestoreResponse(resp);
      if (resp?.status == 200) {
        setLoader2(false);
        setTimeout(() => {
          navigate("/subscriptions");
        }, 3000);
      }
    } catch (error) {
      setRestoreResponse(error.response);
      setLoader2(false);
      // console.log(error);
    }
  };
  return (
    <div>
      <DashboardWrapper>
        <div>
          <h2 align="center">{t("subscriptionDetails")}</h2>
        </div>
        <div className={`cd-subscription-container ${loader ? "not-appeared" : "appeared"}`}>
          <div>
            {t("name")} : {response?.data?.title}
          </div>
          <div>
            {t("details")} : {response?.data?.description}
          </div>
          <div>
            {t("price")}(INR) : ₹{response?.data?.price_inr}
          </div>
          <div>
            {t("price")}(USD) : ${response?.data?.price_usd}
          </div>
          <div>
            {t("price")}(GBP) : £{response?.data?.price_pond}
          </div>
          <div>
            {t("price")}(EUR) : €{response?.data?.price_euro}
          </div>
          <div>
            {t("validity")} :{response?.data?.validity} {t("days")}
          </div>

          {/* show the delete button if subscription not deleted */}
          {response?.data?.is_active && (
            <div>
              {/* check if user has permission */}
              {(userPermission.includes("update_subscription") || user?.role === "superuser") && (
                <Link className="cd-common-anchor-tag" to={`/update-subscription/${suid}`}>
                  {t("update")}
                </Link>
              )}
              {(userPermission.includes("delete_subscription") || user?.role === "superuser") && (
                <Button
                  onClick={(e) => setShowConfirmDelete(!showConfirmDelete)}
                  className="cd-btn"
                >
                  {t("deactivate")} {loader2 && <Loader2 />}
                </Button>
              )}
            </div>
          )}

          {/* show the restore button if subscription is deleted */}
          {(userPermission.includes("delete_subscription") || user?.role === "superuser") &&
            response?.data?.is_active === false && (
              <div>
                <Button onClick={restoreSubscription} className="cd-btn">
                  {t("restore")} {loader2 && <Loader2 />}
                </Button>
              </div>
            )}

          {/* show the delete message if subscription deleted successfully */}
          {deleteResponse?.status == 200 && (
            <div className="cd-err-msg">{t("subscriptionDeactivateMsg")}! Redirecting...</div>
          )}

          {/* show the restore message if subscription restored successfully */}
          {restoreResponse?.status == 200 && (
            <div className="cd-success-msg">{t("subscriptionRestoreMsg")}! Redirecting...</div>
          )}
          {restoreResponse?.data?.detail && (
            <div className="cd-err-msg">{restoreResponse?.data?.detail[0]?.msg}</div>
          )}
          {restoreResponse?.data?.detail?.detail && (
            <div className="cd-err-msg">{restoreResponse?.data?.detail?.detail[0]?.msg}</div>
          )}

          {/* show the message if any error encountered from API response */}
          {deleteResponse?.data?.detail && (
            <div className="cd-err-msg">{deleteResponse?.data?.detail[0]?.msg}</div>
          )}
          {deleteResponse?.data?.detail?.detail[0] && (
            <div className="cd-err-msg">{deleteResponse?.data?.detail?.detail[0]?.msg}</div>
          )}

          {/* to show the loader */}
          {loader && <Loader />}
        </div>
        {showConfirmDelete && (
          <div>
            <div>{t("areYouSure")}</div>
            <Button onClick={deactivateSubscription} className="cd-btn">
              {t("yes")}
            </Button>
            <Button onClick={(e) => setShowConfirmDelete(false)} className="cd-btn">
              {t("no")}
            </Button>
          </div>
        )}
      </DashboardWrapper>
    </div>
  );
};

export default SubscriptionDetail;
