import React from "react";

const InputEmail = (props) => (
  <input
    className={props.className}
    id={props.id}
    type="email"
    name={props.name}
    placeholder={props.placeholder}
    onChange={props.onChange}
    autoComplete="off"
    value={props.value}
  ></input>
);

export default InputEmail;
