import React from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardWrapper from "../components/wrappers/DashboardWrapper";
import Heading2 from "../components/headings/Heading2";

const Dashboard = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const { t } = useTranslation();
  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <DashboardWrapper>
        <div>
          <Heading2>
            {t("welcome")} {user?.username}
          </Heading2>
        </div>
      </DashboardWrapper>
    </div>
  );
};

export default Dashboard;
