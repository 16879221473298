import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import axiosFetch from "../../axiosConfig";
import { getAllRoles } from "../../redux/slice/backendUserSlice";
import { ADDROLE_ENDPOINT } from "../../api";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import InputField from "../../components/inputs/InputField";
import Button from "../../components/buttons/Button";
import { Card1 } from "../../components/commoncomps/Card1";
import Loader2 from "../../components/loaders/Loader2";

export const AddRole = () => {
  const { t } = useTranslation();
  // get the user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [response, setResponse] = useState(null);
  const [loader, setLoader] = useState(false);

  // get the user permission from admin state
  const { userPermission } = useSelector((state) => state.admin);

  // constant to disable the add role button
  const isDisabled = role.length < 3 || role.length > 20 ? true : false;

  const addUserRole = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const resp = await axiosFetch.post(ADDROLE_ENDPOINT, {
        title: role,
      });

      if (resp.status === 201) {
        dispatch(getAllRoles()); //dispatch action creator to refresh the data in redux store
      }

      setLoader(false);
      setResponse(resp);
    } catch (error) {
      setLoader(false);
      setResponse(error?.response);
    }
  };

  // check the user permission
  if (!userPermission.includes("create_role") && user?.role !== "superuser") {
    return (
      <DashboardWrapper>
        <div className="cd-err-msg">You don't have access to view this page</div>
      </DashboardWrapper>
    );
  }
  return (
    <DashboardWrapper>
      <Card1>
        <form>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="username">
              {t("addRole")} :
            </label>
            <InputField
              className="cd-input-field"
              type="text"
              name="username"
              id="username"
              placeholder={t("placeHolderRole")}
              onChange={(e) => setRole(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="cd-input-container">
            {response?.status === 201 && (
              <div className="cd-success-msg">
                {t("roleCreatedMsg")}
                <br />
                {t("role")} : {response?.data?.title}
              </div>
            )}
            {response?.data?.detail && (
              <div className="cd-err-msg">{response?.data?.detail[0]?.msg}</div>
            )}
            {response?.data?.detail?.detail && (
              <div className="cd-err-msg">{response?.data?.detail?.detail[0]?.msg}</div>
            )}
          </div>
          <div>
            <Button className="cd-btn" onClick={addUserRole} disabled={isDisabled}>
              {t("addRole")}
              {loader && <Loader2 />}
            </Button>
          </div>
        </form>
      </Card1>
    </DashboardWrapper>
  );
};
