import React from "react";
import { useTranslation } from "react-i18next";
import InputField from "../inputs/InputField";
import Button from "../buttons/Button";

/**
 * A component for navigating to a specific page.
 * @param {number} inputPage - A constant in which you want to store the entered page value.
 * @param {function} onClick - The function to handle navigating to the entered page.
 * @param {function} onChange - The function to handle the value user entered
 * @returns {JSX.Element} - The page user want to go.
 */

const GoToPage = (props) => {
  const { t } = useTranslation();
  return (
    <div className="cd-go-to-page-container">
      <div className="cd-go-to-page-wrapper">
        <div>
          <InputField
            className="cd-input-field"
            name="cd_page_no"
            placeholder={t("placeholderPageNo")}
            value={props.inputPage}
            onChange={props.onChange}
          ></InputField>
        </div>
        <div>
          <Button className="cd-btn" onClick={props.onClick}>
            {t("go")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GoToPage;
