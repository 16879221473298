import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ASSIGN_PERMISSIONS_ENDPOINT } from "../../api";
import axiosFetch from "../../axiosConfig";
import { getAllRoles, getAllPermissions } from "../../redux/slice/backendUserSlice";
// import { checkUserPermission } from "../../redux/handleUser";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Button from "../../components/buttons/Button";
import Modal from "../../components/commoncomps/Modal";
import InputField from "../../components/inputs/InputField";
import Loader2 from "../../components/loaders/Loader2";

const ManageRoles = ({ setProgress }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [ruid, setRuid] = useState();
  const [roleTitle, setRoleTitle] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [assignPermissionsResponse, setAssignPermissionsResponse] = useState();
  const [loader, setLoader] = useState(true);

  //getting all roles from admin state
  const {
    rolesData: { allRoles },
  } = useSelector((state) => state.admin);
  //getting all permissions from admin state
  const {
    permissionsData: { allPermissions },
  } = useSelector((state) => state.admin);
  // get user permissions from redux state
  const { userPermission } = useSelector((state) => state.admin);
  // get user info from local storage
  const user = JSON.parse(localStorage.getItem("user"));

  //getting all the roles
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(getAllRoles()).then(() => {
      setProgress(100);
      setLoader(false);
    });
  }, []);

  //getting all the permissions
  useEffect(() => {
    if (allPermissions.length < 1) {
      setLoader(true);
      dispatch(getAllPermissions()).then(() => {
        setLoader(false);
      });
    }
  }, []);

  const handleModal = (ruid, title) => {
    if (openModal) setOpenModal(false);
    else {
      setRuid(ruid);
      setRoleTitle(title);
      //making permission array empty for new role
      setPermissions([]);
      //iterating allRoles array to get already assigned permission
      allRoles.forEach((role) => {
        // if allRoles ruid and clicked role ruid matched
        // add already assigned permissions to the permission array
        if (role?.ruid === ruid) {
          role?.permissions.forEach((data) => {
            setPermissions((prevPermissions) => [...prevPermissions, data?.permission?.codename]);
          });
        }
      });
      setOpenModal(true);
      // making assignPermissionResponse blank so on every success response message it gets hidden
      setAssignPermissionsResponse();
    }
  };
  const handlePermissions = (value) => {
    const updatedValues = [...permissions];
    const valueIndex = updatedValues.indexOf(value);
    if (valueIndex === -1) {
      // add the value in array if it not exist in array
      updatedValues.push(value);
    } else {
      // remove value if it exist in array
      updatedValues.splice(valueIndex, 1);
    }
    setPermissions(updatedValues);
  };
  // console.log(permissions);
  const assignPermissions = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(ASSIGN_PERMISSIONS_ENDPOINT, {
        ruid: ruid,
        permissions: permissions,
      });
      if (resp?.status === 201) {
        // dispatching getAllRoles() action to update the state which contains allRoles
        dispatch(getAllRoles());
        setLoader(false);
      }
      setAssignPermissionsResponse(resp);
    } catch (error) {
      setLoader(false);
      setAssignPermissionsResponse(error?.response);
    }
  };
  // console.log(assignPermissionsResponse);
  return (
    <div>
      <DashboardWrapper>
        <h2 align="center">{t("roles")}</h2>
        {(userPermission.includes("create_role") || user?.role === "superuser") && (
          <div>
            <Link to="/create-role">{t("addRole")}</Link>
          </div>
        )}
        {!allRoles?.data?.detail && allRoles?.length !== 0 && (
          <div className={`${loader ? "not-appeared" : "appeared"}`}>
            {/* {loader && <Loader />} */}
            <table className="role-table" cellSpacing="20px">
              <thead>
                <tr>
                  <th>{t("roleName")} </th>
                  {/* <th>{t("edit")} </th> */}
                  <th>{t("permissions")}</th>
                </tr>
              </thead>
              <tbody>
                {allRoles?.map((data, index) => (
                  <tr key={index}>
                    <td>{data?.title}</td>
                    {/* <td>
                      <Button className="cd-btn" text="Edit" disabled={true}>
                        Edit
                      </Button>
                    </td> */}
                    <td>
                      <Button
                        onClick={() => handleModal(data?.ruid, data?.title)}
                        className="cd-btn"
                      >
                        {t("permissions")}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {openModal && (
          <Modal handleModal={handleModal}>
            <div>
              <h3 align="center">Role : {roleTitle}</h3>
            </div>
            <div>
              {allPermissions?.data?.detail && (
                <div className="cd-err-msg">{allPermissions?.data?.detail[0]?.msg}</div>
              )}
              {allPermissions?.data?.detail?.detail && (
                <div className="cd-err-msg">{allPermissions?.data?.detail?.detail[0]?.msg}</div>
              )}
              {!allPermissions?.data?.detail && (
                <>
                  {allPermissions?.map((permission, index) => (
                    <div key={index}>
                      <InputField
                        className="cd-input-field"
                        value={permission?.codename}
                        type="checkbox"
                        name="cd_role_permissions"
                        checked={permissions.includes(permission?.codename) ? true : ""}
                        onChange={(e) => handlePermissions(e.target.value)}
                      />
                      <span>{permission?.permission}</span>
                    </div>
                  ))}
                  {/* {(userPermission.includes("update_role")||user?.role === "superuser")&&( */}
                  {(userPermission.includes("update_role") || user?.role === "superuser") && (
                    <div className="cd-btn-container">
                      <Button
                        className="cd-btn"
                        onClick={assignPermissions}
                        disabled={user?.ruid === ruid ? true : false}
                      >
                        {t("update")} {loader && <Loader2 />}
                      </Button>
                    </div>
                  )}
                </>
              )}
              {assignPermissionsResponse?.status === 201 && (
                <div className="cd-success-msg">{t("permissionsUpdatedMsg")}</div>
              )}
              {assignPermissionsResponse?.data?.detail?.detail && (
                <div className="cd-err-msg">
                  Oops!, you don't have permission to update permissions <br />
                  Please contanct to admin
                </div>
              )}
            </div>
          </Modal>
        )}
        {allRoles?.data?.detail && (
          <div className="cd-err-msg">{allRoles?.data?.detail.detail[0].msg}</div>
        )}
      </DashboardWrapper>
    </div>
  );
};
export default ManageRoles;
