import { useSelector } from "react-redux";

export const setUserInLocalStorage = (data) => {
  localStorage.setItem("user", data);
};

export function removeUserFromLocalStorage() {
  localStorage.removeItem("user");
}

export const scrollToTop = () => {
  const dashboardContent = document.querySelector(".cd-dashboard-content");
  dashboardContent.scrollTo({
    top: 0,
  });
};

/**
 * Check if the current user has a specific permission.
 * Check any one condition by default but every condition if checkFor=route
 * @param {Array} permissions - The permission string to check.
 * @param {string} [checkFor="element"] - The context for which the permission is being checked.
 *                                         Possible values: "element" (default), "route".
 * @returns {boolean} - True if the user has the specified permission, false otherwise.
 */
export const checkUserPermission = (permissions, checkFor = "element") => {
  // get user permission from local storage
  const { userPermission } = useSelector((state) => state.admin);
  // get user from local storage
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    if (checkFor === "element") {
      if (
        permissions.some((permission) => userPermission.includes(permission)) ||
        user?.username === "admin"
      ) {
        return true;
      }
      return false;
    }

    if (checkFor === "route") {
      if (
        !permissions.every((permission) => userPermission.includes(permission)) &&
        user?.username !== "admin"
      ) {
        // return false neither user have permission nor user is admin
        return false;
      }
      return true;
    }
  }
};
