import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DELETE_PERMISSION } from "../../api";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import { getAllPermissions } from "../../redux/slice/backendUserSlice";
import Modal from "../../components/commoncomps/Modal";
import axiosFetch from "../../axiosConfig";
import Button from "../../components/buttons/Button";

const Permissions = ({ setProgress }) => {
  const [deletePermissionResponse, setDeletePermissionResponse] = useState({});
  const [permissionToBeDelete, setPermissionToBeDelete] = useState("");
  // constant to use useDispatch
  const dispatch = useDispatch();
  // get user from local storage
  const user = JSON.parse(localStorage.getItem("user"));
  // get translation function from useTranslation()
  const { t } = useTranslation();

  // get all permissions
  useEffect(() => {
    setProgress(40);
    dispatch(getAllPermissions()).then(() => {
      setProgress(100);
    });
  }, [deletePermissionResponse]);

  // get permissions from redux state
  const {
    permissionsData: { allPermissions },
  } = useSelector((state) => state.admin);
  //   console.log(allPermissions);

  // function to call the api of delete permission
  const deletePermission = async () => {
    try {
      const resp = await axiosFetch.delete(
        DELETE_PERMISSION + `?code_name=${permissionToBeDelete.permission}`
      );
      setDeletePermissionResponse({ resp: resp, index: permissionToBeDelete?.index });
    } catch (error) {
      setDeletePermissionResponse({ resp: error.response, index: permissionToBeDelete?.index });
    }
  };

  // const permissionsByType = useMemo(() => {
  //   const permissionsMap = {};
  //   allPermissions.forEach((permission) => {
  //     const { type } = permission;
  //     if (!permissionsMap[type]) {
  //       permissionsMap[type] = [];
  //     }
  //     permissionsMap[type].push(permission);
  //   });
  //   return permissionsMap;
  // }, [allPermissions]);

  if (user?.role !== "superuser") {
    return (
      <DashboardWrapper>
        <div className="cd-err-msg">You don't have permission to view this page</div>
      </DashboardWrapper>
    );
  }
  return (
    <DashboardWrapper>
      <div>
        <Link to="/create-permission">Create permission</Link>
      </div>
      <div className="cd-permissions-list-container">
        {/* {Object.entries(permissionsByType).map(([type, permissions]) => (
          <div key={type}>
            {type === "1" && <h4 className="cd-permission-type">Type 1 : User</h4>}
            {type === "2" && <h4 className="cd-permission-type">Type 2 : Role</h4>}
            {type === "3" && <h4 className="cd-permission-type">Type 3 : Permission</h4>}
            {type === "4" && <h4 className="cd-permission-type">Type 4 : Subscription</h4>}
            {type === "5" && <h4 className="cd-permission-type">Type 5 : Tax</h4>}
            {type === "6" && <h4 className="cd-permission-type">Type 6 : Coupon</h4>}
            {permissions.map((permission) => (
              <div key={permission.codename} className="cd-permission-list-element">
                <div>Permission: {permission.permission}</div>
                <div>Code name: {permission.codename}</div>
              </div>
            ))}
          </div>
        ))} */}
        {allPermissions.map((permission, index) => (
          <div key={permission.codename} className="cd-permission-list-element">
            <div>Permission: {permission.permission}</div>
            <div>Type: {permission.type}</div>
            <div>Code name: {permission.codename}</div>
            <span
              className="cd-err-msg"
              style={{ cursor: "pointer" }}
              onClick={() =>
                setPermissionToBeDelete({ permission: permission.codename, index: index })
              }
            >
              {t("delete")}{" "}
            </span>
          </div>
        ))}
        {permissionToBeDelete.permission && (
          <Modal handleModal={() => setPermissionToBeDelete("")}>
            <div className="cd-delete-permission-warning">
              <h3>Delete Permission : {permissionToBeDelete.permission}</h3>
              <Button className="cd-btn" style={{ cursor: "pointer" }} onClick={deletePermission}>
                {t("yes")}{" "}
              </Button>
              <Button
                className="cd-btn"
                style={{ cursor: "pointer" }}
                onClick={() => setPermissionToBeDelete("")}
              >
                {t("no")}
              </Button>
              {/* code block to show the API responses */}
              {deletePermissionResponse?.resp?.status === 200 && (
                <div className="cd-success-msg">Permission Deleted!</div>
              )}
              {deletePermissionResponse?.resp?.data?.detail && (
                <div className="cd-err-msg">
                  {deletePermissionResponse?.resp?.data?.detail[0]?.msg}
                </div>
              )}
              {deletePermissionResponse?.resp?.data?.detail?.detail && (
                <div className="cd-err-msg">
                  {deletePermissionResponse?.resp?.data?.detail?.detail[0]?.msg}
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
    </DashboardWrapper>
  );
};

export default Permissions;
