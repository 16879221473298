import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import axiosFetch from "../../axiosConfig";
import { VERIFY_TOKEN } from "../../api";

const VerifyToken = () => {
  const [redirect, setRedirect] = useState(false);
  const url = window.location.search;
  const navigate = useNavigate();
  useEffect(() => {
    const tokenVerify = async () => {
      try {
        await axiosFetch.get(VERIFY_TOKEN + url);
        setRedirect(true);
        setTimeout(() => {
          navigate("login");
        }, 2000);
      } catch (error) {
        return false;
      }
    };

    //calling the function immediatly
    tokenVerify();
  }, []);
  return (
    <div>
      {redirect ? (
        <div className="cd-email-verification-msg">
          Verification Success! Redirecting to login...
        </div>
      ) : (
        <div className="cd-err-msg">Token Expired...</div>
      )}
      {/* {
              response?.data?.detail && (
                <div className="cd-err-msg">
                    Token Expired...
                </div>
              )  
            } */}
    </div>
  );
};
export default VerifyToken;
