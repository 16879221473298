import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axiosFetch from "../../axiosConfig";
import { UPDATE_SUBSCRIPTION_ENDPONT, GET_SUBSCRIPTION_DETAIL } from "../../api";
import { getAllFeatures } from "../../redux/slice/backendUserSlice";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import { Card1 } from "../../components/commoncomps/Card1";
import InputField from "../../components/inputs/InputField";
import TextArea from "../../components/inputs/TextArea";
import Button from "../../components/buttons/Button";
import Loader from "../../components/loaders/Loader";

const AddSubscription = ({ setProgress }) => {
  const dispatch = useDispatch();
  const { suid } = useParams();
  const [subscriptionDetail, setSubscriptionDetail] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [subscriptionDescription, setSubscriptionDescription] = useState("");
  const [subscriptionPriceINR, setSubscriptionPriceINR] = useState("");
  const [subscriptionPriceUSD, setSubscriptionPriceUSD] = useState("");
  const [subscriptionPricePound, setSubscriptionPricePound] = useState("");
  const [subscriptionPriceEuro, setSubscriptionPriceEuro] = useState("");
  const [subscriptionValidity, setSubscriptionValidity] = useState("");
  const [subscriptionSalePriceINR, setSubscriptionSalePriceINR] = useState("");
  const [subscriptionSalePriceUSD, setSubscriptionSalePriceUSD] = useState("");
  const [subscriptionSalePricePound, setSubscriptionSalePricePound] = useState("");
  const [subscriptionSalePriceEuro, setSubscriptionSalePriceEuro] = useState("");
  const [updateSubscriptionResponse, setupdateSubscriptionResponse] = useState("");
  const [loader, setLoader] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [featureError, setFeatureError] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  // constant to use useTranslation hook
  const { t } = useTranslation();

  // get user from localstorage
  const user = JSON.parse(localStorage.getItem("user"));

  const subscriptionDetailsPayload = {
    suid,
    title: subscriptionName,
    description: subscriptionDescription,
    price_inr: subscriptionPriceINR,
    sale_price_inr: subscriptionSalePriceINR,
    price_usd: subscriptionPriceUSD,
    sale_price_usd: subscriptionSalePriceUSD,
    price_pond: subscriptionPricePound,
    sale_price_pond: subscriptionSalePricePound,
    price_euro: subscriptionPriceEuro,
    sale_price_euro: subscriptionSalePriceEuro,
    validity: subscriptionValidity,
    is_deleted: false,
    features: selectedFeatures,
  };
  // console.log(subscriptionDetailsPayload);

  // getting the subscription detail
  useEffect(() => {
    setProgress(40);
    const getSubscriptionDetail = async () => {
      try {
        const resp = await axiosFetch.get(GET_SUBSCRIPTION_DETAIL + suid);
        setSubscriptionDetail(resp);
        setProgress(100);
      } catch (error) {
        setSubscriptionDetail(error?.response);
        setProgress(100);
      }
    };
    getSubscriptionDetail();
  }, []);
  // console.log(subscriptionDetail);

  // initializing all the subscription detail information in variables
  useEffect(() => {
    setSubscriptionName(subscriptionDetail?.data?.title || "");
    setSubscriptionDescription(subscriptionDetail?.data?.description || "");
    setSubscriptionPriceINR(subscriptionDetail?.data?.price_inr || "");
    setSubscriptionPriceUSD(subscriptionDetail?.data?.price_usd || "");
    setSubscriptionPricePound(subscriptionDetail?.data?.price_pond || "");
    setSubscriptionPriceEuro(subscriptionDetail?.data?.price_euro || "");
    setSubscriptionSalePriceINR(subscriptionDetail?.data?.sale_price_inr || "");
    setSubscriptionSalePriceUSD(subscriptionDetail?.data?.sale_price_usd || "");
    setSubscriptionSalePricePound(subscriptionDetail?.data?.sale_price_pond || "");
    setSubscriptionSalePriceEuro(subscriptionDetail?.data?.sale_price_euro || "");
    setSubscriptionValidity(subscriptionDetail?.data?.validity || "");
    // render the feature selecter n times, n is length of subscriptionDetail features array
    const featuresArray = [];
    subscriptionDetail?.data?.features?.forEach((data) => {
      featuresArray.push({
        feature_code: data.feature.feature_code,
        quantity: data.quantity,
      });
      setSelectedFeatures(featuresArray);
    });
  }, [subscriptionDetail]);
  // console.log(selectedFeatures);

  // getting all features
  useEffect(() => {
    dispatch(getAllFeatures());
  }, []);
  const {
    featuresDetail: { allFeatures },
  } = useSelector((state) => state.admin);
  const { userPermission } = useSelector((state) => state.admin);
  // console.log(allFeatures);
  const isDisabled = !!(
    subscriptionName.length === 0 ||
    subscriptionPriceINR.length === 0 ||
    subscriptionValidity.length === 0 ||
    selectedFeatures.length === 0
  );

  // submitting the subscription to backend
  const updateSubscription = async () => {
    setQuantityError(false);
    // check if any value is empty in payload
    selectedFeatures.map((data, index) => {
      if (data?.feature_code !== "" || data?.quantity === "") {
        setQuantityError(true);
      }
    });
    setLoader(true);
    try {
      const resp = await axiosFetch.put(UPDATE_SUBSCRIPTION_ENDPONT, subscriptionDetailsPayload);
      setupdateSubscriptionResponse(resp);
      if (resp.status === 200) {
        setLoader(false);
        setFeatureError(false);
        setQuantityError(false);
      }
    } catch (error) {
      setupdateSubscriptionResponse(error?.response);
      setLoader(false);
    }
  };
  // console.log(updateSubscriptionResponse);

  // to add selected feature in selectedFeaturesArray
  const addFeature = (index, value, valueType) => {
    const existingIndex = selectedFeatures.findIndex((feature) => feature.feature_code === value);
    setQuantityError(false);
    // console.log(existingIndex);
    const featureObject = {
      feature_code: valueType === "feature" ? value : "",
      quantity: valueType === "quantity" ? parseInt(value) : "",
    };
    if (existingIndex === -1 && valueType === "feature") {
      const updatedFeaturesArray = [...selectedFeatures];
      updatedFeaturesArray.push(featureObject);
      setSelectedFeatures(updatedFeaturesArray);
    } else if (existingIndex !== -1 && valueType === "feature") {
      const updatedFeaturesArray = [...selectedFeatures];
      updatedFeaturesArray.splice(existingIndex, 1);
      setSelectedFeatures(updatedFeaturesArray);
    } else {
      const updatedFeaturesArray = [...selectedFeatures];
      try {
        const length = updatedFeaturesArray.length - 1;
        updatedFeaturesArray[length].quantity = value;
      } catch (error) {
        setFeatureError(true);
      }
      // updatedFeaturesArray[index].quantity = value;
      setSelectedFeatures(updatedFeaturesArray);
    }
  };
  // console.log(selectedFeatures);

  if (!userPermission.includes("update_subscription") && user?.role !== "superuser") {
    return (
      <DashboardWrapper>
        <div className="cd-err-msg">You don't have access to view this page</div>
      </DashboardWrapper>
    );
  }

  return (
    <div>
      <DashboardWrapper>
        <div>
          <h2 align="center">{t("updateSubscription")} </h2>
        </div>
        <Card1>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("name")}:
            </label>
            <InputField
              className="cd-input-field"
              type="text"
              name="cd_subscription_title"
              placeholder="Subscription Name"
              value={subscriptionName}
              onChange={(e) => setSubscriptionName(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(INR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_price_inr"
              placeholder="Enter Price"
              value={subscriptionPriceINR}
              onChange={(e) => setSubscriptionPriceINR(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")}(INR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Sale Price"
              name="cd_subscription_sale_price_inr"
              value={subscriptionSalePriceINR}
              onChange={(e) => setSubscriptionSalePriceINR(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")} (USD):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Price"
              name="cd_subscription_price_usd"
              value={subscriptionPriceUSD}
              onChange={(e) => setSubscriptionPriceUSD(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")} (USD):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Sale Price"
              name="cd_subscription_sale_price_usd"
              value={subscriptionSalePriceUSD}
              onChange={(e) => setSubscriptionSalePriceUSD(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")} (GBP):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Price"
              name="cd_subscription_price_gbp"
              value={subscriptionPricePound}
              onChange={(e) => setSubscriptionPricePound(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {" "}
              {t("salePrice")} (GBP):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Sale Price"
              name="cd_subscription_sale_price_gbp"
              value={subscriptionSalePricePound}
              onChange={(e) => setSubscriptionSalePricePound(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(EUR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Price"
              name="cd_subscription_price_eur"
              value={subscriptionPriceEuro}
              onChange={(e) => setSubscriptionPriceEuro(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")}(EUR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Sale Price"
              name="cd_subscription_sale_price_gbp"
              value={subscriptionSalePriceEuro}
              onChange={(e) => setSubscriptionSalePriceEuro(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("validity")}(In Days):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Validity"
              name="cd_subscription_validity"
              value={subscriptionValidity}
              onChange={(e) => setSubscriptionValidity(e.target.value)}
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("description")}:
            </label>
            <TextArea
              className="cd_input_textarea"
              cols="24"
              rows="10"
              name="cd_subscription_desc"
              value={subscriptionDescription}
              onChange={(e) => setSubscriptionDescription(e.target.value)}
            >
              {subscriptionDescription}
            </TextArea>
          </div>
          <div className="cd-input-container">
            <h3 align="center"> {t("feature")} </h3>
            <table>
              <thead>
                <tr>
                  <th>{t("featureType")}</th>
                  <th>{t("quantity")}</th>
                </tr>
              </thead>
              <tbody>
                {allFeatures.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <div className="cd-features-list-selector-left">
                        <InputField
                          type="checkbox"
                          className="cd-input-field"
                          value={data?.feature_code}
                          checked={
                            selectedFeatures.findIndex(
                              (feature) => feature.feature_code == data?.feature_code
                            ) != -1
                              ? true
                              : ""
                          }
                          onChange={(e) => addFeature(index, e.target.value, "feature")}
                        />
                        <label htmlFor="">{data?.feature_type}</label>
                      </div>
                    </td>
                    <td>
                      <InputField
                        className="cd-input-field cd-features-list-selector-right"
                        type="number"
                        min="1"
                        value={
                          selectedFeatures.find(
                            (feature) => feature?.feature_code === data?.feature_code
                          )?.quantity || ""
                        }
                        onChange={(e) => addFeature(index, e.target.value, "quantity")}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {featureError && <div className="cd-err-msg">Please select feature first</div>}
          </div>
          {loader && <Loader />}
          {updateSubscriptionResponse?.status == 200 && (
            <div className="cd-success-msg">{t("subscriptionUpdatedMsg")}</div>
          )}
          {updateSubscriptionResponse?.data?.detail && (
            <div className="">
              <div className="cd-err-msg">{updateSubscriptionResponse?.data?.detail[0]?.msg}</div>
            </div>
          )}
          {updateSubscriptionResponse?.data?.detail?.detail && (
            <div className="">
              <div className="cd-err-msg">
                {updateSubscriptionResponse?.data?.detail?.detail[0]?.msg}
              </div>
            </div>
          )}
          {quantityError && <div className="cd-err-msg">Please select quantity</div>}
          <div>
            <Button
              className="cd-btn"
              onClick={updateSubscription}
              disabled={isDisabled}
              title={isDisabled ? "Please fill all inputs to enable" : "Submit"}
            >
              {" "}
              {t("update")}{" "}
            </Button>
          </div>
        </Card1>
      </DashboardWrapper>
    </div>
  );
};

export default AddSubscription;
