import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./slice/backendUserSlice"; // Import your admin reducer here

export const store = configureStore({
  reducer: {
    admin: adminReducer, // Define your admin slice reducer here
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializability check
    }),
});

export default store;
