import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_ALL_PERMISSIONS,
  GET_ROLES,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  GET_ALL_SUBSCRIPTIONS_ENDPOINT,
  GET_ALL_BACKEND_USERS,
  GET_ALL_FEATURES,
  GET_ALL_FRONTENDUSER,
  GET_ALL_TAX_MODELS,
  GET_ALL_COUPONS,
  GET_USER_PERMISSION,
} from "../../api";
import { removeUserFromLocalStorage, setUserInLocalStorage } from "../handleUser";
import axiosFetch from "../../axiosConfig";

export const adminLogin = createAsyncThunk("adminLogin", async (data) => {
  try {
    const resp = await axiosFetch.post(LOGIN_ENDPOINT, data);
    // console.log(resp);
    const user = {
      username: resp?.data?.user?.username,
      token: resp?.data?.token,
      ruid: resp?.data?.user?.role?.ruid,
      role: resp?.data?.user?.role?.title,
    };
    if (resp.status === 200) setUserInLocalStorage(JSON.stringify(user));
    return resp;
  } catch (error) {
    return error;
  }
});

export const adminLogout = createAsyncThunk("adminLogout", async () => {
  try {
    const resp = await axiosFetch.delete(LOGOUT_ENDPOINT);
    removeUserFromLocalStorage();
    return resp;
  } catch (error) {
    return error;
  }
});

export const getUserPermission = createAsyncThunk("getUserPermission", async () => {
  try {
    const resp = await axiosFetch.get(GET_USER_PERMISSION);
    return resp;
  } catch (error) {
    return error.response;
  }
});

export const getAllRoles = createAsyncThunk("getAllRoles", async () => {
  try {
    const resp = await axiosFetch.get(GET_ROLES, {});
    return resp;
  } catch (error) {
    return error?.response;
  }
});

export const getAllPermissions = createAsyncThunk("getAllPermissions", async () => {
  try {
    const resp = await axiosFetch.get(GET_ALL_PERMISSIONS, {});
    return resp;
  } catch (error) {
    return error?.response;
  }
});

export const getAllFrontEndUsers = createAsyncThunk("getAllFrontEndUsers", async (data) => {
  try {
    const resp = await axiosFetch.get(
      GET_ALL_FRONTENDUSER + `?limit=${data.limit}&offset=${data.offset}`
    );
    return resp;
  } catch (error) {
    return error?.response;
  }
});

export const getAllSubscriptions = createAsyncThunk("getAllSubscriptions", async (data) => {
  try {
    const resp = await axiosFetch.get(
      GET_ALL_SUBSCRIPTIONS_ENDPOINT + `?limit=${data.limit}&offset=${data.offset}`
    );
    return resp;
  } catch (error) {
    return error.response;
  }
});

export const getAllBackendUsers = createAsyncThunk("getAllBackendUsers", async (data) => {
  try {
    const resp = await axiosFetch.get(
      GET_ALL_BACKEND_USERS + `?limit=${data.limit}&offset=${data.offset}`
    );
    return resp;
  } catch (error) {
    return error?.response;
  }
});

export const getAllFeatures = createAsyncThunk("getAllFeatures", async () => {
  try {
    const resp = await axiosFetch.get(GET_ALL_FEATURES);
    return resp;
  } catch (error) {
    return error?.response;
  }
});

export const getAllTaxModels = createAsyncThunk("getAllTaxModels", async (data) => {
  try {
    const resp = await axiosFetch.get(
      GET_ALL_TAX_MODELS + `?limit=${data.limit}&offset=${data.offset}`
    );
    return resp;
  } catch (error) {
    return error?.response;
  }
});

export const getAllCoupons = createAsyncThunk("getAllCoupons", async (data) => {
  try {
    const resp = await axiosFetch.get(
      GET_ALL_COUPONS + `?limit=${data.limit}&offset=${data.offset}`
    );
    return resp;
  } catch (error) {
    return error.response;
  }
});

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isLoading: false,
    adminLogindata: [],
    isError: false,
    backendUsersData: {
      allBackendUsers: [],
    },
    rolesData: {
      allRoles: [],
    },
    userPermission: [],
    permissionsData: {
      allPermissions: [],
    },
    subscriptionsData: {
      allSubscriptions: [],
    },
    subscriptionDetail: {
      subscriptionDetailData: [],
    },
    featuresDetail: {
      allFeatures: [],
    },
    frontEndUserDetails: {
      allFrontEndUsers: [],
    },
    taxModelDetails: {
      allTaxModels: [],
    },
    couponDetails: {
      allCoupons: [],
    },
  },
  extraReducers: (builder) => {
    // backend user Login
    builder.addCase(adminLogin.fulfilled, (state, { payload }) => {
      if (payload.data) state.adminLogindata = payload?.data;
      else {
        state.adminLogindata = payload?.response?.data;
      }
    });
    builder.addCase(adminLogin.rejected, (state, { payload }) => {
      state.isError = true;
      state.adminLogindata = payload;
    });

    // backend user logout
    builder.addCase(adminLogout.fulfilled, (state) => {
      state.isLoading = false;
      state.adminLogindata = [];
    });
    builder.addCase(adminLogout.rejected, (state, { payload }) => {
      state.adminLogindata = payload;
    });

    // to get user permissions
    builder.addCase(getUserPermission.fulfilled, (state, { payload }) => {
      if (payload?.status !== 200) {
        state.userPermission = [];
      } else {
        // filter the permission codenames;
        state.userPermission = payload?.data?.user_permissions?.map((permission) => {
          return permission.codename;
        });
      }
    });

    // to get all roles
    builder.addCase(getAllRoles.fulfilled, (state, { payload }) => {
      if (payload?.status !== 200) {
        state.rolesData.allRoles = payload;
      } else {
        state.rolesData.allRoles = payload?.data;
      }
    });
    builder.addCase(getAllRoles.rejected, (state) => {
      state.isLoading = false;
    });

    // to get all permissions
    builder.addCase(getAllPermissions.fulfilled, (state, { payload }) => {
      if (payload?.status !== 200) {
        state.permissionsData.allPermissions = payload;
      } else {
        state.permissionsData.allPermissions = payload.data;
      }
    });
    builder.addCase(getAllPermissions.rejected, (state, { payload }) => {
      state.permissionsData.error = payload;
    });

    // to get all subscriptions
    builder.addCase(getAllSubscriptions.fulfilled, (state, { payload }) => {
      if (payload?.status !== 200) {
        state.subscriptionsData.allSubscriptions = payload;
      } else {
        state.subscriptionsData.allSubscriptions = payload?.data;
      }
    });
    builder.addCase(getAllSubscriptions.rejected, (state) => {
      state.isLoading = false;
    });

    // to get all backend users
    builder.addCase(getAllBackendUsers.fulfilled, (state, { payload }) => {
      if (payload?.status !== 200) {
        state.backendUsersData.allBackendUsers = payload;
      } else {
        state.backendUsersData.allBackendUsers = payload?.data;
      }
    });
    builder.addCase(getAllBackendUsers.rejected, (state, { payload }) => {
      state.backendUsersData.allBackendUsers = payload;
    });

    // to get all features
    builder.addCase(getAllFeatures.fulfilled, (state, { payload }) => {
      state.featuresDetail.allFeatures = payload?.data;
    });
    builder.addCase(getAllFeatures.rejected, (state, { payload }) => {
      state.featuresDetail.error = payload;
    });

    // to get all front end users
    builder.addCase(getAllFrontEndUsers.fulfilled, (state, { payload }) => {
      if (payload?.status !== 200) {
        state.frontEndUserDetails.allFrontEndUsers = payload;
      } else {
        state.frontEndUserDetails.allFrontEndUsers = payload?.data;
      }
    });
    builder.addCase(getAllFrontEndUsers.rejected, (state, { payload }) => {
      state.frontEndUserDetails.error = payload;
    });

    // to get all tax models
    builder.addCase(getAllTaxModels.fulfilled, (state, { payload }) => {
      if (payload?.status !== 200) {
        state.taxModelDetails.allTaxModels = payload;
      } else {
        state.taxModelDetails.allTaxModels = payload?.data;
      }
    });
    builder.addCase(getAllTaxModels.rejected, (state, { payload }) => {
      state.taxModelDetails.error = payload?.detail;
    });

    // to get all coupons
    builder.addCase(getAllCoupons.fulfilled, (state, { payload }) => {
      state.couponDetails.allCoupons = payload?.data;
    });
    builder.addCase(getAllCoupons.rejected, (state, { payload }) => {
      state.couponDetails.error = payload?.data;
    });
  },
});

export default adminSlice.reducer;
