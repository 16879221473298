import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import DashboardSidebar from "../DashboardSidebar";
// import { checkUserPermission } from "../../redux/handleUser";
import Navbar from "../Navbar";
import MenuItem from "../commoncomps/MenuItem";
import "../../style/dashboard.css";

const DashboardWrapper = (props) => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const { pathname } = window.location;
  // const searchParams = window.location.search;

  //redirect user when user's info not available in localStorage
  useEffect(() => {
    if (!user) window.location.replace("/login");
  }, []);

  // get userPermission from redux state
  const { userPermission } = useSelector((state) => state.admin);

  // scroll the page to top when search params change
  // useEffect(() => {
  //   const dashboardContent = document.querySelector(".cd-dashboard-content");
  //   if (dashboardContent) {
  //     dashboardContent.scrollTo({
  //       top: 0,
  //       behavior: "smooth", // Make the scrolling behavior smooth
  //     });
  //   }
  // }, [searchParams]);

  return (
    <div className="cd_dashboard_wrapper">
      <div className="clearfix">
        <div className="cd-dashboard-left-section">
          <DashboardSidebar>
            <MenuItem className={`${pathname === "/dashboard" ? "cd-active" : ""}`}>
              <Link className="cd-common-anchor-tag" to="/dashboard">
                {t("dashboard")}
              </Link>
            </MenuItem>

            {/* check if the user has the permissions */}
            {(userPermission.includes("read_role") ||
              userPermission.includes("create_role") ||
              user?.role === "superuser") && (
              <MenuItem
                className={`${pathname === "/manage-roles" || pathname === "/create-role" ? "cd-active" : ""}`}
              >
                <Link className="cd-common-anchor-tag" to="/manage-roles">
                  {t("roles")}
                </Link>
              </MenuItem>
            )}

            {(userPermission.includes("read_subscription") ||
              userPermission.includes("create_subscription") ||
              user?.role === "superuser") && (
              <MenuItem
                className={`${pathname === "/subscriptions" || pathname.startsWith("/subscription") || pathname === "/add-subscription" || pathname.startsWith("/update-subscription") ? "cd-active" : ""}`}
              >
                <Link className="cd-common-anchor-tag" to="/subscriptions">
                  {t("subscription")}
                </Link>
              </MenuItem>
            )}

            {(userPermission.includes("read_user") ||
              userPermission.includes("create_user") ||
              user?.role === "superuser") && (
              <MenuItem
                className={`${pathname === "/users" || pathname.startsWith("/user") || pathname === "/create-user" ? "cd-active" : ""}`}
              >
                <Link className="cd-common-anchor-tag" to="/users">
                  {`${t("backend")} ${t("user")}`}
                </Link>
              </MenuItem>
            )}

            {(userPermission.includes("read_user") ||
              userPermission.includes("create_user") ||
              user?.role === "superuser") && (
              <MenuItem
                className={`${pathname === "/frontend-users" || pathname.startsWith("/frontend-user-details/") ? "cd-active" : ""}`}
              >
                <Link className="cd-common-anchor-tag" to="/frontend-users">
                  {`${t("frontend")} ${t("user")}`}
                </Link>
              </MenuItem>
            )}

            {(userPermission.includes("read_tax") ||
              userPermission.includes("create_tax") ||
              user?.role === "superuser") && (
              <MenuItem
                className={`${pathname === "/tax-models" || pathname.startsWith("/tax-models/") || pathname.startsWith("/add-tax-model") ? "cd-active" : ""}`}
              >
                <Link className="cd-common-anchor-tag" to="/tax-models">
                  {t("taxModel")}
                </Link>
              </MenuItem>
            )}

            {(userPermission.includes("read_coupon") ||
              userPermission.includes("create_code") ||
              user?.role === "superuser") && (
              <MenuItem
                className={`${pathname === "/coupons" || pathname.startsWith("/coupons/") ? "cd-active" : ""}`}
              >
                <Link className="cd-common-anchor-tag" to="/coupons">
                  {t("coupon")}
                </Link>
              </MenuItem>
            )}
            {user?.role === "superuser" && (
              <MenuItem
                className={`${pathname === "/permissions" || pathname.startsWith("/create-permission") ? "cd-active" : ""}`}
              >
                <Link className="cd-common-anchor-tag" to="/permissions">
                  {t("permissions")}
                </Link>
              </MenuItem>
            )}
          </DashboardSidebar>
        </div>
        <div className="clearfix cd-dashboard-right-section">
          <div>
            <Navbar />
          </div>
          <div className="cd-dashboard-content">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardWrapper;
