import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllSubscriptions } from "../../redux/slice/backendUserSlice";
import { scrollToTop } from "../../redux/handleUser";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import GoToPage from "../../components/pagination/GoToPage";
import Pagination from "../../components/pagination/Pagination";

const Subscriptions = ({ setProgress }) => {
  // constant to use useDispatch hook
  const dispatch = useDispatch();

  // contant to use useTranslation
  const { t } = useTranslation();

  // constant to use useNavigate hook
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  const page = new URLSearchParams(window.location.search).get("page");
  const [currentPage, setCurrentPage] = useState(() => {
    return page ? parseInt(page) : 1;
  });
  const [inputPage, setInputPage] = useState("");
  const [limit] = useState(10);
  const [loader, setLoader] = useState(true);

  //getting subscriptions data from admin state
  const {
    subscriptionsData: { allSubscriptions, error },
  } = useSelector((state) => state.admin);
  // get user permission from admin state
  const { userPermission } = useSelector((state) => state.admin);

  //getting all subscriptions
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(
      getAllSubscriptions({
        limit: limit,
        offset: (currentPage - 1) * limit,
      })
    ).then(() => {
      setLoader(false);
      setProgress(100);
      scrollToTop();
    });
  }, [limit, currentPage, dispatch, setProgress]);

  // functions for handling pagination
  const handlePrev = () => {
    let tempCurrentPage = currentPage;
    if (currentPage > 1) {
      tempCurrentPage -= 1;
      setCurrentPage(parseInt(tempCurrentPage));
      navigate(`?page=${tempCurrentPage}`);
      if (tempCurrentPage === 1) {
        navigate(window.location.pathname);
        return;
      }
    }
  };

  const handleNext = () => {
    let tempCurrentPage = currentPage;
    if (tempCurrentPage >= Math.ceil(allSubscriptions?.total / limit)) return;
    tempCurrentPage += 1;
    setCurrentPage(parseInt(tempCurrentPage));
    navigate(`?page=${tempCurrentPage}`);
  };

  const handleInputPage = () => {
    if (inputPage < 0 || inputPage === "" || inputPage > Math.ceil(allSubscriptions?.total / limit))
      return;
    setCurrentPage(parseInt(inputPage));
    navigate(`?page=${inputPage}`);
  };

  return (
    <div>
      <DashboardWrapper>
        {error === "Network Error" && (
          <h3 align="center" className="cd-err-msg">
            Internal Server Error
          </h3>
        )}
        <div>
          <h2 align="center">{t("subscription")}</h2>
        </div>

        {/* check if the user has permission */}
        {(userPermission.includes("create_subscription") || user?.role === "superuser") && (
          <div>
            <Link to="/add-subscription">{t("addSubscription")}</Link>
          </div>
        )}

        {!allSubscriptions?.data?.detail && (
          <div className={loader ? "not-appeared" : "appeared"}>
            <div className="cd-subscription-container">
              {allSubscriptions?.subscriptions?.map((subscription, index) => (
                <div key={index} className="cd-subscription-list-item">
                  <div>
                    {t("name")} : {subscription?.title}
                    {subscription?.is_active === false && (
                      <div className="cd-err-msg">Deactivated</div>
                    )}
                  </div>
                  <div>
                    {t("price")}(INR) : ₹{subscription?.price_inr}
                  </div>
                  <div>
                    {t("price")}(USD) : ${subscription?.price_usd}
                  </div>
                  <div>
                    {t("price")}(GBP) : £{subscription?.price_pond}
                  </div>
                  <div>
                    {t("price")}(EUR) : €{subscription?.price_euro}
                  </div>
                  <div>
                    {t("validity")} :{subscription?.validity} {t("days")}
                  </div>
                  <div>
                    <Link
                      to={`/subscription/${subscription?.suid}`}
                      className="cd-common-anchor-tag"
                    >
                      {t("details")}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <Pagination
              onClickPrev={handlePrev}
              onClickNext={handleNext}
              currentPage={currentPage}
            />
            <GoToPage
              inputPage={inputPage}
              onChange={(e) => setInputPage(e.target.value)}
              onClick={handleInputPage}
            ></GoToPage>
          </div>
        )}

        {allSubscriptions?.data?.detail && (
          <div className="cd-err-msg">{allSubscriptions?.data?.detail[0]?.msg}</div>
        )}
        {allSubscriptions?.data?.detail?.detail && (
          <div className="cd-err-msg">{allSubscriptions?.data?.detail?.detail[0]?.msg}</div>
        )}
      </DashboardWrapper>
    </div>
  );
};

export default Subscriptions;
