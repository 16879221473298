import axios from "axios";
import { BASE_URL } from "./api";
import { removeUserFromLocalStorage } from "./redux/handleUser";

const axiosFetch = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
const user = JSON.parse(localStorage.getItem("user")); // get the user from localstorage

// configure the request behaviour
axiosFetch.interceptors.request.use(
  (config) => {
    const token = user?.token;
    if (token) {
      config.headers.Authtoken = `${token}`; // pass the token in headers
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// configure the response behaviour
axiosFetch.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response.status === 401) {
      // remove the user from localStorage if 401 error code got from any response
      removeUserFromLocalStorage();
      window.location.replace("/login"); // redirect user to login page
    }
    return Promise.reject(error);
  }
);

export default axiosFetch;
