import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axiosFetch from "../../axiosConfig";
import { UPDATE_COUPON, GET_COUPON_DETAIL } from "../../api";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Heading2 from "../../components/headings/Heading2";
import { Card1 } from "../../components/commoncomps/Card1";
import InputField from "../../components/inputs/InputField";
import Loader2 from "../../components/loaders/Loader2";
import Button from "../../components/buttons/Button";

const UpdateCoupon = ({ setProgress }) => {
  // constant to use the useTranslation hook
  const { t } = useTranslation();

  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [couponDetail, setCouponDetail] = useState({
    title: "",
    coupon_code: "",
    percentage: "",
  });
  const [updateCouponResponse, setUpdateCouponResponse] = useState("");
  // getting coupon code from URL parameters
  const { couponCode } = useParams();

  // get the coupon details
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    async function getCouponDetails() {
      try {
        const resp = await axiosFetch.get(GET_COUPON_DETAIL + couponCode);
        setCouponDetail(resp.data?.coupon);
        setProgress(100);
        setLoader(false);
      } catch (error) {
        setCouponDetail(error.response);
        setLoader(false);
        setProgress(100);
      }
    }
    getCouponDetails();
  }, []);
  // console.log(couponDetail);

  const handleCouponInputs = async (value, inputName) => {
    let tempCouponInputValues = {
      title: inputName === "title" ? value : couponDetail?.title,
      coupon_code: inputName === "coupon_code" ? value : couponDetail?.coupon_code,
      percentage: inputName === "percentage" ? value : couponDetail?.percentage,
    };
    setCouponDetail(tempCouponInputValues);
  };

  // function to update the coupon code
  const updateCouponCode = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(UPDATE_COUPON, {
        title: couponDetail?.title,
        coupon_code: couponDetail?.coupon_code,
        percentage: couponDetail?.percentage,
        coupon_type: "percentage",
        is_active: true,
      });
      if (resp?.status === 200) {
        setLoader2(false);
        setUpdateCouponResponse(resp?.data);
      }
    } catch (error) {
      setLoader2(false);
      setUpdateCouponResponse(error?.response?.data);
    }
  };

  return (
    <div>
      <DashboardWrapper>
        <div>
          <Heading2 align="center"> {t("updateCoupon")} </Heading2>
        </div>
        <Card1 className={loader ? "not-appeared" : "appeared"}>
          <div className="cd-input-container">
            <label className="cd-input-label">{t("title")} : </label>
            <InputField
              className="cd-input-field"
              placeholder={t("placeholderTitle")}
              name="cd_coupon_title"
              value={couponDetail?.title}
              onChange={(e) => handleCouponInputs(e.target.value, "title")}
            ></InputField>
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label"> {t("percentage")} : </label>
            <InputField
              type="number"
              className="cd-input-field"
              name="cd_coupon_percent"
              placeholder={t("placeholderPercentage")}
              value={couponDetail?.percentage}
              onChange={(e) => handleCouponInputs(e.target.value, "percentage")}
            ></InputField>
          </div>
          {updateCouponResponse?.title && (
            <div className="cd-success-msg">{t("couponUpdatedMsg")}</div>
          )}
          {updateCouponResponse?.detail && (
            <div className="cd-err-msg">{updateCouponResponse?.detail[0]?.msg}</div>
          )}
          {updateCouponResponse?.detail?.detail && (
            <div className="cd-err-msg">{updateCouponResponse?.detail?.detail[0]?.msg}</div>
          )}
          <Button className="cd-btn" onClick={updateCouponCode}>
            {" "}
            {t("update")} {loader2 && <Loader2 />}
          </Button>
        </Card1>
      </DashboardWrapper>
    </div>
  );
};

export default UpdateCoupon;
