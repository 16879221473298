import React from "react";
import "../../style/modal.css";

/**
 * @param {function} handleModal - function which is using to open and close the modal
 * @returns {JSX.Element} - The JSX element of modal
 */

const Modal = (props) => {
  const closeModal = () => {
    props.handleModal();
  };
  return (
    <div>
      <div className="cd_modal">
        <div className="cd_modal-content clearfix">
          <span onClick={closeModal} className="cd_modal_close">
            &times;
          </span>
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
