import React, { useState } from "react";
import { CREATE_PERMISSION } from "../../api";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Heading2 from "../../components/headings/Heading2";
import { Card1 } from "../../components/commoncomps/Card1";
import InputField from "../../components/inputs/InputField";
import Button from "../../components/buttons/Button";
import Loader2 from "../../components/loaders/Loader2";
import axiosFetch from "../../axiosConfig";

const CreatePermission = () => {
  const [permissionTitle, setPermissionTitle] = useState("");
  const [permissionType, setPermissionType] = useState("");
  const [permissionCode, setPermissionCode] = useState("");
  const [createPermissionResponse, setCreatePermissionResponse] = useState();
  const [loader2, setLoader2] = useState(false);

  let createPermissionPayload = {
    permission: permissionTitle,
    type: permissionType,
    codename: permissionCode,
  };
  const createPermission = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(CREATE_PERMISSION, createPermissionPayload);
      setCreatePermissionResponse(resp);
      setLoader2(false);
    } catch (error) {
      setLoader2(false);
      setCreatePermissionResponse(error.response);
    }
  };
  const isDisabled =
    permissionTitle.length < 3 || permissionType.length < 1 || permissionCode.length < 3
      ? true
      : false;
  return (
    <DashboardWrapper>
      <Heading2 align="center">Create permission</Heading2>
      <Card1>
        <div align="center">
          <div className="cd-input-container">
            <label className="cd-input-label">Permission Title :</label>
            <InputField
              className="cd-input-field"
              value={permissionTitle}
              name="cd_permission_title"
              onChange={(e) => setPermissionTitle(e.target.value)}
              placeholder="Enter permission title"
            ></InputField>
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label">Permission Type :</label>
            <InputField
              className="cd-input-field"
              value={permissionType}
              name="cd_permission_type"
              onChange={(e) => setPermissionType(e.target.value)}
              placeholder="Enter permission type"
            ></InputField>
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label">Permission Code :</label>
            <InputField
              className="cd-input-field"
              value={permissionCode}
              name="cd_permission_code"
              onChange={(e) => setPermissionCode(e.target.value)}
              placeholder="Enter permission code"
            ></InputField>
          </div>
          <Button className="cd-btn" onClick={createPermission} disabled={isDisabled}>
            Create {loader2 && <Loader2 />}
          </Button>
        </div>
      </Card1>
      <div align="center">
        {createPermissionResponse?.status === 201 && (
          <div className="cd-success-msg">Permission created!</div>
        )}
        {createPermissionResponse?.data?.detail && (
          <div className="cd-err-msg">{createPermissionResponse?.data?.detail[0]?.msg}</div>
        )}
        {createPermissionResponse?.data?.detail?.detail && (
          <div className="cd-err-msg">{createPermissionResponse?.data?.detail?.detail[0]?.msg}</div>
        )}
      </div>
    </DashboardWrapper>
  );
};

export default CreatePermission;
