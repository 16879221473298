import { React, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import axiosFetch from "../../axiosConfig";
import { REGISTER_ENDPOINT } from "../../api";
import { getAllRoles } from "../../redux/slice/backendUserSlice";
import Heading2 from "../../components/headings/Heading2";
import InputField from "../../components/inputs/InputField";
import InputEmail from "../../components/inputs/InputEmail";
import InputPassword from "../../components/inputs/InputPassword";
import Button from "../../components/buttons/Button";
import Loader2 from "../../components/loaders/Loader2";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import { Card1 } from "../../components/commoncomps/Card1";

const CreateUser = ({ setProgress }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [roleId, setRoleId] = useState("");
  const [response, setResponse] = useState(null);
  const [loader, setLoader] = useState(false);
  const [inputError, setInputError] = useState({
    email: "",
    password: "",
    userName: "",
    roleId: "",
  });
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    rolesData: { allRoles },
  } = useSelector((state) => state.admin);
  const { userPermission } = useSelector((state) => state.admin);

  //getting all roles

  useEffect(() => {
    setProgress(40);
    dispatch(getAllRoles()).then(() => {
      setProgress(100);
    });
  }, []);

  if (!user?.token) {
    return <Navigate to="/login" />;
  }

  //input validations, start

  //email validation
  const handleEmail = (value) => {
    setEmail(value);
    let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i;
    if (!emailRegex.test(email)) {
      setInputError({ ...inputError, email: "Invalid Email" });
    } else {
      setInputError({ ...inputError, email: "" });
    }
  };

  //username validation
  const handleUserName = (value) => {
    setUserName(value);
    let userRegex = /^[a-zA-Z0-9_]{6,30}$/;
    if (userName.length < 6)
      setInputError({
        ...inputError,
        userName: "length should be grater than 6",
      });
    else if (!userRegex.test(userName)) setInputError({ ...inputError, userName: "Invalid Input" });
    else setInputError({ ...inputError, userName: "" });
  };

  //password validation
  const handlePassword = (value) => {
    setPassword(value);
    let passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password))
      setInputError({ ...inputError, password: "Invalid Password" });
    else setInputError({ ...inputError, password: "" });
  };
  // to disable the sign up button until all fields are filled
  const isDisabled =
    email.length < 4 ||
    userName.length < 6 ||
    password.length < 8 ||
    roleId === "" ||
    inputError?.email ||
    inputError?.password ||
    inputError?.userName
      ? true
      : false;

  //input validations, end

  //api calls
  const registerUser = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const responseData = await axiosFetch.post(REGISTER_ENDPOINT, {
        username: userName,
        email: email,
        password: password,
        role_id: roleId,
      });
      if (responseData) {
        setLoader(false);
      }
      setResponse(responseData);
    } catch (error) {
      setResponse(error.response.data);
      if (error) setLoader(false);
    }
  };

  // check user permission
  if (
    !(userPermission.includes("create_user") && userPermission.includes("read_role")) &&
    user?.role !== "superuser"
  ) {
    return (
      <DashboardWrapper>
        <div className="cd-err-msg">You don't have access to view this page</div>
        You should have permission to read roles & to create user to view this page
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper>
      <Card1>
        <div>
          <Heading2 className="cd-user-role-head">{t("createUser")}</Heading2>
        </div>
        <div className="cd-input-container">
          <label className="cd-input-label" htmlFor="email">
            {t("email")}:{" "}
          </label>
          <InputEmail
            className="cd-input-field"
            type="email"
            name="email"
            id="email"
            placeholder={t("placeholderEmail")}
            onChange={(e) => handleEmail(e.target.value)}
            autoComplete="off"
          />
        </div>
        {/* show the error if email key of inputError has a value */}
        <div className="cd-input-container">
          {inputError?.email && <div className="cd-err-msg">{inputError.email}</div>}
        </div>
        <div className="cd-input-container">
          <label className="cd-input-label" htmlFor="username">
            {t("username")} :
          </label>
          <InputField
            className="cd-input-field"
            type="text"
            name="username"
            id="username"
            placeholder={t("placeholderUsername")}
            onChange={(e) => handleUserName(e.target.value)}
            autoComplete="off"
          />
        </div>
        {/* show the error if userNmae key of inputError has a value */}
        <div className="cd-input-container">
          {inputError?.userName && (
            <div className="cd-err-msg">
              {inputError.userName}
              <div>
                space and special characters <br /> not allowed except"_"
              </div>
            </div>
          )}
        </div>
        <div className="cd-input-container">
          <label className="cd-input-label" htmlFor="password">
            {t("password")}:
          </label>
          <InputPassword
            className="cd-input-field"
            type="password"
            name="password"
            id="password"
            placeholder={t("placeholderPassword")}
            onChange={(e) => handlePassword(e.target.value)}
          />
        </div>
        {/* show the error if userNmae key of inputError has a value */}
        <div className="cd-input-container">
          {inputError?.password && (
            <div className="cd-err-msg">
              {inputError.password}
              <div>
                Password should have at least
                <br />a capital & small letter,
                <br />a special character and number
              </div>
            </div>
          )}
        </div>
        <div className="cd-input-container">
          <label className="cd-input-label" htmlFor="roleId">
            {t("role")}:
          </label>
          <select
            className="cd-input-field"
            name=""
            id=""
            onChange={(e) => setRoleId(e.target.value)}
          >
            <option value="">{t("selectRole")}</option>
            {allRoles?.map((data, index) => (
              <option key={index} value={data?.ruid}>
                {data?.title}
              </option>
            ))}
          </select>
        </div>
        <div className="cd-input-container">
          {response?.status === 201 && (
            <div className="cd-success-msg">
              User created successfully, <br />
              please verify email
            </div>
          )}
          {response?.detail && <div className="cd-err-msg">{response?.detail[0]?.msg}</div>}
          {response?.detail?.detail[0] && (
            <div className="cd-err-msg">{response?.detail?.detail[0]?.msg}</div>
          )}
        </div>
        <div className="cd-btn-container">
          <Button id="signUpBtn" className="cd-btn" onClick={registerUser} disabled={isDisabled}>
            {" "}
            {t("createUser")}
            {loader && <Loader2 />}
          </Button>
        </div>
      </Card1>
    </DashboardWrapper>
  );
};

export default CreateUser;
