export const BASE_URL = "https://codecms.codeholic.in";
// export const BASE_URL = "http://127.0.0.1:8000";

export const REGISTER_ENDPOINT = `/backend-user/register`;
export const VERIFY_TOKEN = `/backend-user/verify-token`;
export const LOGIN_ENDPOINT = `/backend-user/login`;
export const LOGOUT_ENDPOINT = `/backend-user/logout`;
export const CHANGE_PASSWORD_ENDPOINT = "/backend-user/create-password";

export const ADDROLE_ENDPOINT = `/backend-user/add-role`;
export const GET_ROLES = "/backend-user/roles";

export const GET_USER_PERMISSION = "/backend-user/user-permissions";
export const GET_ALL_PERMISSIONS = "/backend-user/permissions";
export const CREATE_PERMISSION = "/backend-user/create-permission";
export const DELETE_PERMISSION = "/backend-user/delete-permission";
export const ASSIGN_PERMISSIONS_ENDPOINT = "/backend-user/assign-permission";

export const ADD_SUBSCRIPTION_ENDPONT = "/backend-user/add-subscription";
export const GET_ALL_SUBSCRIPTIONS_ENDPOINT = "/backend-user/subscriptions";
export const GET_SUBSCRIPTION_DETAIL = "/backend-user/subscriptions/";
export const DEACTIVATE_SUBSCRIPTION_ENDPOINT = "/backend-user/deactivate-subscription/";
export const UPDATE_SUBSCRIPTION_ENDPONT = "/backend-user/update-subscription";

export const GET_ALL_BACKEND_USERS = "/backend-user/get-all";
export const GET_USER = `/backend-user/get`;
export const UPDATE_BACKEND_USER = "/backend-user/update-user";
export const SEND_EMAIL_TOKEN = "/backend-user/send-token";
export const GET_ALL_FEATURES = "/backend-user/features";

export const GET_ALL_FRONTENDUSER = "/backend-user/get-all-frontend-users";
export const GET_FRONTENDUSER_DETAIL = "/backend-user/get-frontend-user/";
export const UPDATE_FRONTEND_USER = "/backend-user/update-frontend-user";
export const SEARCH_FRONTEND_USER = "/backend-user/search-frontenduser";

export const GET_ALL_TAX_MODELS = "/backend-user/get-tax-models";
export const GET_ALL_TAX_MODEL_DETAILS = "/backend-user/get-tax-model/";
export const ADD_TAX_MODEL = "/backend-user/add-tax-model";
export const UPDATE_TAX_MODEL = "/backend-user/update-tax-model";
export const ADD_TAX_REGION = "/backend-user/add-tax-region";
export const ADD_TAX_FIELD = "/backend-user/add-tax-field";
export const DELETE_TAX_REGION = "/backend-user/delete-tax-region";
export const DELETE_TAX_FIELD = "/backend-user/delete-tax-field";

export const GET_ALL_COUPONS = "/backend-user/list-coupons";
export const GET_COUPON_DETAIL = "/backend-user/get-coupon/";
export const CREATE_COUPON = "/backend-user/create-coupon";
export const DELETE_COUPON = "/backend-user/delete-coupon";
export const UPDATE_COUPON = "/backend-user/update-coupon";
export const SEARCH_COUPON = "/backend-user/search-coupons";
