import { React } from "react";
import { Link } from "react-router-dom";
import "../search/search.css";

import InputField from "../inputs/InputField";

/**
 * Component to search user, subscription,
 * @param {string} value - the input value
 * @param {string} clickURL - the url prefix, where the user will redirect after click on a result
 * @param {string} elemuid - the result element unique id eg. cuid, suid, coupon_code etc
 * @param {string} title - the title you want to show in result list it can be name, username etc.
 * @param {function} onChange - the function used to set the input in a variable
 * @param {Array} searchResponse - the search result array
 * @returns {JSX.Element} - the JSX  element
 */

const Search = (props) => {
  return (
    <div className="cd-search-container">
      <div className="cd-search-keyword-input cd-input-container">
        {/* getting value and onChange from props */}
        <InputField
          className="cd-input-field"
          name="cd_search_keyword"
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
        ></InputField>
      </div>

      {/* getting the search result from props and rendered here */}
      {props?.searchResponse?.length > 0 && props.value?.length > 3 ? (
        <div className="cd-search-result-list">
          {props?.searchResponse?.map((result, index) => {
            const uidValue = result[props.elemuid];
            const title = result[props.title];
            return (
              <Link
                key={index}
                to={`/${props.clickURL}/${uidValue}`}
                className="cd-common-anchor-tag"
              >
                <div key={index} className="cd-search-result-list-element">
                  {title}
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        props.value?.length > 3 &&
        props?.searchResponse && (
          <div className="cd-search-result-list cd-search-result-list-element">No result!</div>
        )
      )}
    </div>
  );
};

export default Search;
